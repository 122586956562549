/* eslint-disable react/require-default-props */
import PropTypes from "prop-types";

import { createContext, useCallback, useMemo, useState } from "react";

export const ModalContext = createContext(false);

// eslint-disable-next-line react/prop-types
export function ModalContextProvider({ children }) {
  // the value that will be given to the context
  const [enabled, toggleEnabledFun] = useState(false);
  const [priceBrl, togglePriceBrl] = useState(false);
  const [tier, setTier] = useState("");
  const [enableBuyPix, toggledEnableBuyPix] = useState(false);
  const [activeBuyPix, toogledEnableActiveBuyPix] = useState({});
  const [firstModalShow, setFirstModalShow] = useState(false);

  const toggleEnabled = useCallback((val) => toggleEnabledFun(val), []);
  const toggleModalEnabled = useCallback(
    () => toggleEnabledFun(!enabled),
    [enabled]
  );
  const toggleEnableBuyPix = useCallback(() => {
    toggledEnableBuyPix(!enableBuyPix);
  }, [enableBuyPix]);
  const toogleEnableActiveBuyPix = useCallback(
    () => toogledEnableActiveBuyPix(!activeBuyPix),
    [activeBuyPix]
  );

  const modalConextProviderValue = useMemo(
    () => ({
      enabled,
      toggleModalEnabled,
      setTier,
      priceBrl,
      togglePriceBrl,
      tier,
      enableBuyPix,
      toggleEnableBuyPix,
      activeBuyPix,
      toggledEnableBuyPix,
      toogleEnableActiveBuyPix,
      toogledEnableActiveBuyPix,
      toggleEnabled,
      firstModalShow,
      setFirstModalShow,
      toggleEnabledFun,
    }),
    [
      enabled,
      toggleModalEnabled,
      setTier,
      priceBrl,
      togglePriceBrl,
      tier,
      enableBuyPix,
      toggleEnableBuyPix,
      activeBuyPix,
      toggledEnableBuyPix,
      toogleEnableActiveBuyPix,
      toogledEnableActiveBuyPix,
      toggleEnabled,
      firstModalShow,
      setFirstModalShow,
      toggleEnabledFun,
    ]
  );

  return (
    // the ModalContextProvider gives access to the context to its children
    <ModalContext.Provider value={modalConextProviderValue}>
      {children}
    </ModalContext.Provider>
  );
}

ModalContextProvider.propTypes = {
  children: PropTypes.node,
};

export default ModalContextProvider;
