import { useQuery } from 'react-query';
import { http } from '../../../../infra/http';
import { PaymentStatus, TransferoTransactionStatus } from '../types';

export function useCheckPaymentPix(transactionId: string) {
  return useQuery(
    `/v1/transaction/${transactionId}`,
    async () => {
      const { data } = await http.get<TransferoTransactionStatus>(
        `/v1/transaction/${transactionId}`
      );

      return data;
    },
    {
      refetchInterval: (data) => (data?.status === PaymentStatus.Confirmed ? false : 5000),
      enabled: !!transactionId
    }
  );
}
