import { createTheme } from "@mui/material/styles";
import AnekLatin from '../../../assets/fonts/AnekLatin.ttf';
import AudioWide from '../../../assets/fonts/Audiowide.ttf';
export default createTheme({
	palette: {
		mode: "dark",
		primary: {
			main: "#FFFFFF",
		},
		secondary: {
			main: "#1DFDFF",
		},
	},
	typography: {
		weight: "400",
		size: "23px",
	},
	components:{
		MuiCssBaseline: {
			styleOverrides:`
			@font-face{
				font-family: "Anek Latin";
				src: url(${AnekLatin});
			}
			@font-face{
				font-family: "Audiowide";
				src: url(${AudioWide});
			}
			`
		}
	}
});
