import create from 'zustand';
import { UserMe } from '../types';

const useUser = create<{ user: UserMe | null; setUser: (user: UserMe) => void }>((set) => ({
  user: null,
  setUser: (user: UserMe) =>
    set((state) => {
      return { ...state, user };
    })
}));

export default useUser;
