import { IS_DEV, SPACESHIP_PROMO } from '../../../../infra/config';
import { useCallback, useEffect, useState } from 'react';
import { showError } from '../../../../infra/helpers/alerts';
import Sentry from '../../../../infra/sentry';
import { getReadProvider } from '../../../../core/features/lootbox/services';
import { TicketModel } from '../../../../core/features/lootbox/models/ticket.model';
import { useTicketsStore } from '../../../../core/features/lootbox/store/tickets';
import { getSpaceshipIqLootboxContract, getSpaceshipIqRevealContract, getSpaceshipLootboxContract, getSpaceshipRevealContract } from '../services';
import axios from 'axios';
import { LootBoxNFT_v2, ShipReveal, ShipRevealIQ } from '@universusgame/smart-contracts';

export function useSpaceshipTicketsPrepareds(wallet: string) {
  const [isLoading, setIsLoading] = useState(false);
  const { items, updateItems, updatedTime } = useTicketsStore();

  const onLoad = useCallback(async () => {
    const provider = getReadProvider();

    const lootBoxContract = getSpaceshipLootboxContract(provider);
    const lootBoxIqContract = getSpaceshipIqLootboxContract(provider);
    const revealContract = getSpaceshipRevealContract(provider);
    const revealIqContract = getSpaceshipIqRevealContract(provider);

    const actives = await revealContract.getActiveRequestsByAccount(wallet);
    const activesIq = await revealIqContract.getActiveRequestsByAccount(wallet);

    console.log('activesIq', activesIq);

    const data: { contractRevel: ShipReveal | ShipRevealIQ; isIq: boolean; contractLootbox: LootBoxNFT_v2; _hex: any }[] = [
      ...actives.map(a => ({ ...a, contractRevel: revealContract, isIq: false, contractLootbox: lootBoxContract })),
      ...activesIq.map(a => ({ ...a, contractRevel: revealIqContract, isIq: true, contractLootbox: lootBoxIqContract }))
    ];

    const result: TicketModel[] = [];

    try {
      for (const item of data) {
        const requestId = item._hex;


        const res = await item.contractRevel.requests(requestId);

        console.log('res', res);

        const tokenId = res.boxId.toNumber();

        let image = null; //metadata.image;
        let name = null; //metadata.name;

        const isPromo = res.lootBox.toLowerCase() === SPACESHIP_PROMO.toLowerCase();

        if (isPromo) {
          image = 'https://static.universusgame.net/store-assets/spaceship-promo-box-thumb.png';

          name = 'Spaceship Promo Box';
        } else {
          const metadataUrl = await item.contractLootbox.tokenURI(tokenId);
          const { data: metadata } = await axios.get(`${metadataUrl}`);

          image = metadata.image;
          name = metadata.name;
        }

        console.log('item.isIq', item.isIq);

        result.push(
          new TicketModel({
            id: tokenId,
            tokenId: `${tokenId}`,
            requestId,
            contract: revealContract,
            contractRevel: item.contractRevel,
            isSpaceship: true,
            type: item.isIq ? 'iq' : null,
            status: 'prepared',
            imageUrl: image,
            name
          })
        );
      }
    } catch (err: any) {
      Sentry.captureMessage(
        JSON.stringify({
          wallet,
          action: 'loadTicketsPrepareds'
        })
      );

      Sentry.setContext('data', {
        wallet,
        action: 'loadTicketsPrepareds'
      });
      Sentry.captureException(err);

      if (IS_DEV) {
        showError(err.message);
      } else {
        showError();
      }
    }

    return result;
  }, [wallet]);

  useEffect(() => {
    setIsLoading(true);

    (async () => {
      const items = await onLoad();

      updateItems(items);

      setIsLoading(false);
    })();

    return () => {
      updateItems([]);
    };
  }, [onLoad, updateItems, updatedTime]);

  return {
    isLoading,
    items
  };
}
