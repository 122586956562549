const inputStyle = {
	background: "#2CFFF61D 0% 0% no-repeat padding-box",
	border: "1px solid #1DFDFF !important",
	opacity: 1,
	borderRadius: "5px",
	marginTop: 1,
};

const tabPanelStyle = {
	p: 3,
};

export { inputStyle, tabPanelStyle };
