import { Contract } from 'ethers';
import { GAS_LIMIT } from '../data/constants';
import { params } from '../data/network-params';
import { getProvider, getRevealContract } from '../services';
import { Ticket, TicketMetadata } from '../typings';
import { getSpaceshipIqRevealContract, getSpaceshipRevealContract } from '../../spaceship/services';

export class TicketModel implements Ticket {
  imageUrl: string | null = null;
  name: string | null = null;
  rarity?: string;
  class?: string;
  metadata?: TicketMetadata;
  id!: string;
  type!: null;
  tokenId!: string;
  contract!: Contract;
  contractRevel!: Contract;
  link?: string;
  requestId?: string;
  status: null = null;
  isSpaceship: boolean = false;

  constructor(data?: Partial<Ticket>) {
    Object.assign(this, data);
  }

  get primarySkills() {
    if (!this.metadata?.attributes?.length) {
      return [];
    }

    const skills =
      this.metadata.attributes.filter(
        (item) => isNaN(+item.value) || item.display_type === 'number'
      ) ?? [];

    return skills;
  }

  get secondaySkills() {
    if (!this.metadata?.attributes?.length) {
      return [];
    }

    const skills =
      this.metadata.attributes.filter(
        (item) => Number(item.value) && item.display_type !== 'number'
      ) ?? [];

    return skills;
  }

  async prepare() {
    if (!this.tokenId) {
      throw new Error('Invalid token');
    }

    if (!this.contract.address) {
      throw new Error('Invalid contract address');
    }

    console.log('token prepare', this.tokenId);

    const provider = getProvider();

    await provider.send('eth_requestAccounts', []);

    const signer = provider.getSigner();

    if (this.isSpaceship) {
      const revealContract = this.type === 'iq' ? getSpaceshipIqRevealContract(signer) : getSpaceshipRevealContract(signer);

      const tx = await revealContract.prepare(this.contract.address, this.tokenId, {
        gasLimit: GAS_LIMIT
      });

      return tx.wait();
    }

    const revealContract = getRevealContract(signer, params.reveal);

    const tx = await revealContract.prepare(this.contract.address, this.tokenId, {
      gasLimit: GAS_LIMIT
    });

    return tx.wait();
  }

  async open() {
    if (!this.requestId) {
      throw new Error('Invalid request');
    }

    if (!this.contract.address) {
      throw new Error('Invalid contract address');
    }

    console.log('token open', this.tokenId);
    console.log('token open', this.requestId);

    const provider = getProvider();

    await provider.send('eth_requestAccounts', []);

    const signer = provider.getSigner();

    if (this.isSpaceship) {
      const revealContract = this.type === 'iq' ? getSpaceshipIqRevealContract(signer) : getSpaceshipRevealContract(signer);

      console.log('type', this.type);
      console.log('requestId', this.requestId);

      const r = await revealContract.open(this.requestId);

      await r.wait();

      return r;
    }

    const contract = getRevealContract(signer, params.reveal);

    const r = await contract.open(this.requestId);

    await r.wait();

    return r;
  }
}
