export interface TransferoQuoteCurrencyResponse {
  quoteId: string;
  price: number;
  expireAt: string;
}

export interface TransferoCreatePixResponse {
  referenceId: string;
  qrCodeString: string;
  base64QRCode: string;
  expiresIn: number;
  amount: number;
  id: string;
}

export interface TransferoCreatePixRequest {
  productId: string;
  quantity: number;
}

export interface TransferoTransactionStatus {
  status: PaymentStatus;
  id: string;
}

export enum PaymentStatus {
  Pending = 'PENDING',
  Processing = 'PROCESSING',
  Confirmed = 'CONFIRMED'
}
