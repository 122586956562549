import { createContext, useEffect, useState } from 'react';
import { Unity, useUnityContext } from 'react-unity-webgl';
import { UnityProvider } from 'react-unity-webgl/distribution/types/unity-provider';
import { Box } from '@mui/material';
import ButtonUniversus from '../ui/components/buttonUniversus/index';

export const WebGlContextProvider = createContext<{
  unityProvider: UnityProvider;
  isLoaded: boolean;
  isRunning: boolean;
  sendMessage: (obj: any) => void;
}>({
  unityProvider: {} as any,
  isLoaded: false,
  isRunning: false,
  sendMessage: () => { }
});

export function WebGlProvider({ children }: any) {
  const [show, setShow] = useState(false);
  const [isRunning, setIsRunning] = useState(false);

  const { unityProvider, sendMessage, isLoaded } = useUnityContext({
    loaderUrl: 'build/WebglUnityData.loader.js',
    dataUrl: 'build/WebglUnityData.data',
    frameworkUrl: 'build/WebglUnityData.framework.js',
    codeUrl: 'build/WebglUnityData.wasm',
    streamingAssetsUrl: 'build/streamingAssets'
  });

  useEffect(() => {
    (window as any).__unity__notifyError = (err: string) => {
      alert(err);
    };
    (window as any).__unity__notifyIsReady = () => {
      console.log("WEBGL READY")
      // setShow(true);
    };
    (window as any).__unity__notifyFinished = () => {
      setIsRunning(false);
    };
  }, []);

  const handleMessage = (obj: any) => {
    sendMessage('JsInterop', 'Reveal', JSON.stringify(obj));
    setShow(true);
  };


  return (
    <WebGlContextProvider.Provider
      value={{ sendMessage: handleMessage, unityProvider, isLoaded, isRunning }}>
      <Box
        style={{
          width: '100vw',
          height: '90vh',
          opacity: show ? 1 : 0,
          zIndex: show ? 10 : -99999,
          position: 'fixed',
          top: 0,
          bottom: 0,
          right: 0,
          left: 0
        }}>
        <Box display="flex" flex={1}   >
          <Unity
            disabledCanvasEvents={[
              "abort",
              "animationcancel",
              "animationend",
              "animationiteration",
              "animationstart",
              "auxclick",
              "beforeinput",
              "blur",
              "cancel",
              "canplay",
              "canplaythrough",
              "change",
              "click",
              "close",
              "compositionend",
              "compositionstart",
              "compositionupdate",
              "contextmenu",
              //  "copy",
              "cuechange",
              //  "cut",
              "dblclick",
              "drag",
              "dragend",
              "dragenter",
              "dragleave",
              "dragover",
              "dragstart",
              "drop",
              "durationchange",
              "emptied",
              "ended",
              "error",
              "focus",
              "focusin",
              "focusout",
              "formdata",
              "gotpointercapture",
              "input",
              "invalid",
              "keydown",
              "keypress",
              "keyup",
              "load",
              "loadeddata",
              "loadedmetadata",
              "loadstart",
              "lostpointercapture",
              "mousedown",
              "mouseenter",
              "mouseleave",
              "mousemove",
              "mouseout",
              "mouseover",
              "mouseup",
              //  "paste",
              "pause",
              "play",
              "playing",
              "pointercancel",
              "pointerdown",
              "pointerenter",
              "pointerleave",
              "pointermove",
              "pointerout",
              "pointerover",
              "pointerup",
              "progress",
              "ratechange",
              "reset",
              "resize",
              "scroll",
              "securitypolicyviolation",
              "seeked",
              "seeking",
              "select",
              "selectionchange",
              "selectstart",
              "slotchange",
              "stalled",
              "submit",
              "suspend",
              "timeupdate",
              "toggle",
              "touchcancel",
              "touchend",
              "touchmove",
              "touchstart",
              "transitioncancel",
              "transitionend",
              "transitionrun",
              "transitionstart",
              "volumechange",
              "waiting",
              "webkitanimationend",
              "webkitanimationiteration",
              "webkitanimationstart",
              "webkittransitionend",
              "wheel",
            ]}
            style={{ width: '100%', height: '90%', userSelect: 'none', pointerEvents: 'none' }}
            matchWebGLToCanvasSize
            unityProvider={unityProvider}
          />
        </Box>

        <Box style={{ margin: 'auto' }}>
          <ButtonUniversus onClick={() => {
            setShow(false)
            sendMessage("JsInterop", "Reload");
          }} colorType={'blue'}>
            <>Fechar</>
          </ButtonUniversus>
        </Box>
      </Box>

      <Box style={{ zIndex: 1 }}>{children}</Box>
    </WebGlContextProvider.Provider>
  );
}
