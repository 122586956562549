/* eslint-disable no-prototype-builtins */
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import './style.css';

import { useState } from 'react';
import ShowModal from './showModal';
import ShowPix from './showPix';
import { trackEvent } from '../../../infra/helpers/events';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50vw',
  height: 'fit-content',
  boxShadow: 0,
  p: 4,
  font: 'normal normal bold 21px Anek Latin !important',
  color: '#FFFFFF !important',
  background: '#05213C !important',
  borderRadius: '8px',
  borderTopLeftRadius: '30px !important',
  borderBottomRightRadius: '30px !important',
  border: '2px solid #1DFDFF',
  padding: '0px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};

export default function ModalItem({ product, onClose }) {
  const [enableBuyPix, setEnableBuyPix] = useState(false);

  const [_product, setProduct] = useState(product);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={true}
        onClose={() => {
          onClose();
        }}
        closeAfterTransition>
        <Box item sx={style} className="modalItem" xs={12} md={7}>
          {enableBuyPix ? (
            <ShowPix
              product={_product}
              onClose={() => {
                setEnableBuyPix(false);
              }}
            />
          ) : (
            <ShowModal
              product={_product}
              onChange={(prop, value) => {
                setProduct((prev) => ({ ...prev, [prop]: value }));
              }}
              onClose={() => {
                onClose();
              }}
              onPay={async () => {
                await trackEvent('InitiateCheckout', {
                  productName: _product.name,
                  produtctId: _product.id,
                  price: _product.price,
                  quantity: _product.quantity
                });
                setEnableBuyPix(true);
              }}
            />
          )}
        </Box>
      </Modal>
    </div>
  );
}
