import { useMutation } from 'react-query';
import { http } from '../../../../infra/http';
import { NftCheckIsRevealedRequest } from '../types';

export function useReveal() {
  return useMutation(async (body: NftCheckIsRevealedRequest) => {
    const { data } = await http.post('/v1/nft/reveal', body);

    return data as boolean;
  });
}
