import { Link, useNavigate } from "react-router-dom";
import "./style.css";

import {
	Box,
	CardActions,
	CardContent,
	Container,
	Grid,
	Typography,
} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import GlobalStyles from "@mui/material/GlobalStyles";
import { ThemeProvider } from "@mui/material/styles";

import { useEffect, useState } from "react";
import theme from "../../../core/resources/theme";

function VerifyWallet() {
	const [counter, setCounter] = useState(10);
	const navigate = useNavigate();

	useEffect(() => {
		if (counter > 0) {
			setTimeout(() => setCounter(counter - 1), 1000);
		}

		setTimeout(() => navigate("/"), 10000);
	}, [counter, navigate]);

	return (
		<ThemeProvider theme={theme}>
			<GlobalStyles
				styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
			/>
			<CssBaseline />

			<Container component="main" maxWidth="sm">
				<CssBaseline />

				<Box
					sx={{
						marginTop: 2,
						marginBottom: 5,
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						height: "80vh",
					}}
				>
					<Grid
						sx={{
							minWidth: "400px",
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							justifyContent: "center",
							height: "80vh",
						}}
						style={{ boxShadow: "0px !important" }}
						className="card-content"
					>
						<CardContent className="card-content-header">
							<Grid
								sx={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									flexDirection: "column",
									width: "100%",
									height: "100px",
									paddingLeft: "30px",
								}}
							>
								<Typography
									component="h1"
									sx={{ color: "green !important" }}
									className="font-bold"
								>
									WALLET VALIDADA COM SUCESSO
								</Typography>
								<Typography component="p" color="#FF9133">
									Auto redirecionando para a pagina de login em {counter}{" "}
									segundos.
								</Typography>
							</Grid>
						</CardContent>
						<Link to="/">
							<CardActions
								className="card-content-body"
								sx={{
									color: "black !important",
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									textAlign: "center",
								}}
							>
								Clique aqui para voltar à loja Universus
							</CardActions>
						</Link>
					</Grid>
				</Box>
			</Container>

			{/* Footer */}
			{/* <Footer /> */}
		</ThemeProvider>
	);
}

export default VerifyWallet;
