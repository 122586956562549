/* eslint-disable no-nested-ternary */
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Grid, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import CloseModalImage from '../../../assets/images/close-modal.png';
import { useTransactionPix } from '../../../core/features/transactions/hooks/useTransactionPix';
import { useQuote } from '../../../core/features/transactions/hooks/useQuote';
import { showError, showSuccess } from '../../../infra/helpers/alerts';
import { useCheckPaymentPix } from '../../../core/features/transactions/hooks/useCheckPaymentPix';
import { PaymentStatus } from '../../../core/features/transactions/types';
import { trackEvent } from '../../../infra/helpers/events';

export default function ShowPix({product, onClose}) {

  const [transaction, setTransaction] = useState({});
  const [counter, setCounter] = useState(1);
  const [flexDirection, setFlexDirection] = useState('flex-start');

  const { isLoading, mutate: handleCreatePix, isError } = useTransactionPix();

  const { data: paymentStatus } = useCheckPaymentPix(transaction?.id);

  console.log('paymentStatus', paymentStatus);

  const close = useCallback(() => {
    setTransaction({});
    onClose(); 
  }, [onClose]);

  const { isLoading: isLoadingQuote, data: quote } = useQuote(
    { amount: product.price, quantity: product.quantity },
    true
  );

  useEffect(() => {
    if (paymentStatus?.status === PaymentStatus.Confirmed) {
      showSuccess('Pix recebido com sucesso!').then(async () => {
        await trackEvent('Purchase', {
          productName: product.name,
          produtctId: product.id,
          paymentPrice: transaction.amount,
          paymentQuantity: product.quantity,
        });

        close();
      });
    }
  }, [close, paymentStatus, product.id, product.name, product.price, transaction.amount, product.quantity]);

  useEffect(() => {
    setFlexDirection(isError ? 'center' : 'flex-start');
  }, [setFlexDirection, isError]);

  const createTransactionPix = useCallback(() => {
    handleCreatePix(
      {
        productId: product.id,
        quantity: product.quantity,
      },
      {
        onSuccess(result) {
          setTransaction(result);
          setCounter(result.expiresIn);
        }
      }
    );
  }, [handleCreatePix, product.id, product.quantity]);

  useEffect(() => {
    createTransactionPix();
  }, [createTransactionPix]);

  useEffect(() => {
    if (isLoading || !transaction.referenceId) {
      return;
    }

    const counterTimer = counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);

    return () => {
      clearTimeout(counterTimer);
    };
  }, [counter, isLoading, transaction.referenceId]);

  useEffect(() => {
    if (!isLoading && counter <= 0 && transaction?.referenceId) {
      showError('Expirou o tempo de pagar o pix!').then(() => {
       onClose()
      });
    }
  }, [counter, isLoading, onClose, transaction]);

  return (
    <Grid
      className="containerModal"
      style={{
        width: '70vw',
        height: isLoading || isError ? '40vh' : 'fit-content'
      }}>
      {isLoadingQuote || isLoading ? (
        <h2 className="font-bold">Criando transação pix...</h2>
      ) : (
        <>
          {isError ? (
            <></>
          ) : (
            <>
              <div className="closeModal">
                <img
                  src={CloseModalImage}
                  loading="lazy"
                  onClick={() => {
                    close();
                  }}
                  alt={product.price}
                />
              </div>
              <Grid
                md={3}
                sx={{
                  mt: 5,
                  height: '320px'
                }}>
                <img
                  src={`data:image/png;base64,${transaction.base64QRCode}`}
                  alt="qrcode pix"
                  loading="lazy"
                  className="qrCode"
                />
              </Grid>
            </>
          )}
          <Grid md={isError ? 10 : 6} container sx={{ ml: 5, height: '100%' }}>
            <Grid
              item
              md={12}
              xs={12}
              className="modalContent"
              justifyContent={flexDirection}
              alignItems={flexDirection}>
              {isError ? (
                <>
                  <h2 className="font-bold">Ocorreu um erro ao criar a transação pix!</h2>
                  <p>Volte para o dashboard e tente novamente</p>
                </>
              ) : (
                <>
                  <br />
                  <Typography
                    id="transition-modal-title"
                    variant="h6"
                    component="h2"
                    style={{ textTransform: 'capitalize' }}
                    className="font-bold">
                    {product.name}
                  </Typography>

                  <br />
                  <Typography className="priceEther">${product.price * product.quantity} UNX</Typography>
                  <Typography className="priceEther"></Typography>
                  <Typography sx={{ color: 'text.secondary' }} component="p">
                    {quote?.price > 0 ? quote.price.toFixed(2) : quote?.price} BRL
                  </Typography>
                  <br />

                  <Typography sx={{ color: 'text.secondary', fontWeight: 'bold' }} component="p">
                    Copiar código do PIX
                  </Typography>

                  <Grid
                    container
                    sx={{
                      overflow: 'hidden',
                      width: '100%',
                      display: 'flex',
                      color: '#1DFDFF'
                    }}
                    onClick={() => {
                      navigator.clipboard.writeText(transaction.qrCodeString);
                    }}>
                    <div
                      style={{
                        color: '#1DFDFF',
                        overflow: 'hidden',
                        maxWidth: '80%',
                        maxHeight: '24px'
                      }}>
                      {' '}
                      {transaction.qrCodeString}{' '}
                    </div>
                    <ContentCopyIcon
                      style={{ cursor: 'pointer', marginLeft: '7px' }}
                      onClick={() => {
                        navigator.clipboard.writeText(transaction.qrCodeString);
                      }}
                    />
                  </Grid>

                  <Typography
                    sx={{
                      color: 'text.secondary',
                      display: 'flex',
                      width: '100%'
                    }}
                    component="div">
                    Aguardando pagamento{' '}
                    <strong color="red" style={{ marginLeft: '5px' }}>
                      {counter}
                    </strong>
                  </Typography>
                </>
              )}
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
}
