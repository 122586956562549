/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import './style.css';

import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyles from '@mui/material/GlobalStyles';
import { ThemeProvider } from '@mui/material/styles';

import theme from '../../../core/resources/theme';
import ResponsiveAppBar from '../../components/appbar';
import { AndroidBox } from './components/AndroidBox';
import ModalItem from '../../components/modalitem';
import StatusWallet from '../../components/statuswallet';
import useUser from '../../../core/features/auth/store';
import { useProducts } from '../../../core/features/products/hooks/useProducts';
import { hideLoading, showLoading } from '../../../infra/helpers/alerts';
import { Box, Grid, Typography } from '@mui/material';
import { MiniContainerAndroid } from './components/MiniContainerAndroid';

function Dashboard() {

  const { user } = useUser();

  const [productSelected, setProductSelected] = useState(null);
  const [product, setProduct] = useState(null);


  const navigate = useNavigate();

  const { isLoading, data: products } = useProducts();

  const androids = products?.filter((p) => p.category === 'android') ?? [];

  useEffect(() => {
    if (isLoading && !!user.wallet) {
      showLoading();
    } else {
      hideLoading();
    }
  }, [isLoading]);

  useEffect(() => {
    if (!user.wallet) {
      return navigate('/my-account');
    }
  }, [user.wallet, navigate]);

  useEffect(() => {
    if (androids) {
      setProduct(androids[0]);
    }
  }, [androids]);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
      <CssBaseline />
      {/* {firstModalShow === false ? <ModalVideo video={true} /> : <></>} */}
      {productSelected ? (
        <ModalItem
          onClose={() => {
            setProductSelected(null);
          }}
          product={productSelected}
        />
      ) : null}

      <ResponsiveAppBar />
      <StatusWallet />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={7}>
          <Typography
            fontFamily="Audiowide"
            textAlign="center"
            color="#1DFDFF"
            fontSize="30px"
            fontWeight="500">
            ANDROID
          </Typography>
          <Typography fontFamily="Anek Latin" textAlign="center" fontSize="24px" fontWeight="bold">
            Comprando apenas um pacote de androids, você já pode jogar UNIVERSUS
          </Typography>

          <Grid container xs={12} spacing={2} marginTop="20px">
            <Grid item xs={12} sm={6} p={4}>
              <Box>
                {androids.map((android) => (
                  <MiniContainerAndroid onClick={() => setProduct(android)} />
                ))}
                <MiniContainerAndroid type="old" />
                <MiniContainerAndroid type="old" />
                <MiniContainerAndroid type="old" />
                <MiniContainerAndroid type="old" />
              </Box>
            </Grid>
            {product ? <AndroidBox onClick={() => setProductSelected({...product, quantity: 1})} android={product} /> : <></>}
          </Grid>
        </Grid>
        <Grid item xs={12} padding="20px" sm={5}>
          <Typography textAlign="center" fontFamily="Audiowide" color="#1DFDFF" fontSize="30px">
            SPACESHIPS
          </Typography>
          <Typography fontWeight="bold" textAlign="center" color="#FFF" fontSize="22px">
            VENDAS ENCERRADAS
          </Typography>
          <img src="/images/encerradas.png" alt="" width="100%" />
          <Box display="flex" alignItems="center" flexDirection="column" mb="10px" mt="10px">
            <Typography textAlign="center" fontSize="22px" fontWeight="bold">
              Para adiquirir sua nave, negocie com outros jogadores
            </Typography>
            <Typography 
            textAlign="center" 
            target="_blank"
            fontSize="22px" 
            color="#79EB85" 
            fontWeight="bold" 
            component="a" 
            href='https://opensea.io/collection/universus-spaceships'>
              clicando aqui
            </Typography>{' '}
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export default Dashboard;
