/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-props-no-spreading */
import { useCallback, useState } from 'react';
// import * as router from 'react-router-dom';

import '../Login.css';

import { Divider } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import LoadingButton from '@mui/lab/LoadingButton';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import TextField from '@mui/material/TextField';
import { useAuth } from 'react-aws-cognito-auth';

import { useTranslation } from 'react-i18next';

import { inputStyle } from '../styles';
import { hideLoading, showError, showLoading, showSuccess } from '../../../../infra/helpers/alerts';
import { Auth } from '@aws-amplify/auth';
import axios from 'axios';
import { STORE_API } from '../../../../infra/config';

function LoginTab({ onResetPassword }: { onResetPassword: () => void }) {
  const { t } = useTranslation();

  const auth = useAuth();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const isLoading = auth.isLoading();

  const handleLogin = useCallback(async () => {
    if (!email) {
      return showError('Informe o email');
    }

    if (!password) {
      return showError('Informe a senha');
    }

    showLoading();

    try {
      const { data }: { data: { message: string } } = await axios.post(
        `${STORE_API}/v1/verify-auth`,
        {
          email: email.toLowerCase()
        }
      );

      if (data.message) {
        setTimeout(() => {
          showSuccess(data.message);
        }, 500);
      } else {
        const result = await Auth.signIn({
          username: email.toLowerCase(),
          password
        });

        console.log('result login', result);

        console.log('session', await Auth.currentSession());

        window.location.reload();
      }
    } catch (err: any) {
      setTimeout(() => {
        showError('Usuário ou senha incorretos! Tente recuperar a senha para continuar.');
      }, 500);
    } finally {
      hideLoading();
    }
  }, [email, password]);

  return (
    <FormGroup>
      <TextField
        margin="normal"
        required
        fullWidth
        id="email"
        placeholder={t('account.email')}
        name="email"
        autoComplete="email"
        autoFocus
        className="inputForm inputForm-login"
        onChange={(e) => setEmail(e.target.value)}
        sx={inputStyle}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        name="password"
        placeholder={t('auth.password')}
        type="password"
        id="password"
        autoComplete="current-password"
        className="inputForm inputForm-login"
        onChange={(e) => setPassword(e.target.value)}
        sx={inputStyle}
      />
      <FormControlLabel
        control={<Checkbox value="remember" color="primary" />}
        label={t('auth.remember')}
      />

      <a
        onClick={() => onResetPassword()}
        style={{
          color: 'white',
          textDecoration: 'underline',
          cursor: 'pointer'
        }}>
        {t('auth.forgot')}
      </a>

      <Divider>{t('share.or')}</Divider>
      <br />

      <LoadingButton
        variant="contained"
        loading={isLoading}
        disabled={isLoading}
        onClick={() => {
          handleLogin();

          // logInWithEmailAndPassword(email, password)
          //   .then((data) => {
          //     loginIsSendEnable({
          //       success: true,
          //       message: data.message
          //     });
          //   })
          //   .catch((error) => {
          //     Sentry.captureException(error);
          //     loginIsSendEnable({
          //       success: false,
          //       message: error.message
          //     });
          //   });
        }}
        style={{
          background: 'rgba(44, 255, 246, 0.57)',
          border: '2px solid #1DFDFF',
          borderRadius: '8px',
          borderBottomLeftRadius: '20px',
          borderTopRightRadius: '20px',
          paddingTop: '20px',
          paddingBottom: '20px',
          font: 'normal normal bold 23px/14px Anek Latin',
          color: '#FFFFFF'
        }}
        className="btn-login-default btn-universus-blue">
        {t('auth.login')}
      </LoadingButton>

      {/* <Button
        variant="outlined"
        className="login__btn login__google"
        onClick={() => {
          signInWithGoogle(true)
            .then((data) => {
              loginIsSendEnable({
                success: true,
                message: data.message
              });
            })
            .catch((error) => {
              Sentry.captureException(error);
              loginIsSendEnable({
                success: false,
                message: error.message
              });
            });
        }}
        sx={{
          marginTop: 2,
          backgroundColor: '#ffffff',
          fontSize: '22px'
        }}
        startIcon={<GoogleIcon />}>
        {t('auth.login_with_google')}
      </Button> */}

      <br />
    </FormGroup>
  );
}

export default LoginTab;
