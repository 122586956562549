import "./loading.css";
import PropTypes from "prop-types";

const Loading = ({ children, ready = false }) =>
  ready === false ? (
    <div className="loader-container">
      <div className="spinner"></div>
    </div>
  ) : (
    <>{children}</>
  );

Loading.propTypes = {
  children: PropTypes.node,
  ready: PropTypes.bool,
};

export default Loading;
