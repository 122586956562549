/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-return-assign */
import { useCallback, useState } from 'react';
import './style.css';

import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyles from '@mui/material/GlobalStyles';
import { ThemeProvider } from '@mui/material/styles';

import { Box, Container, TextField, Typography } from '@mui/material';

import theme from '../../../core/resources/theme';
import { showError, showSuccess } from '../../../infra/helpers/alerts';
import ResponsiveAppBar from '../../components/appbar';
import ModalMessage from '../../components/modalmessage';
import StatusWallet from '../../components/statuswallet';
import Sentry from './../../../infra/sentry';
import { Auth } from '@aws-amplify/auth';

import { inputStyle } from './style';
import { SubmitButton } from '../account/form/submit';

export const isEmpty = (property) => {
  return (
    property === '' ||
    property === null ||
    property === undefined ||
    (property.hasOwnProperty('length') && property.length === 0)
  );
};

function AccountChangePassword() {
  const [isLoading, setIsLoading] = useState(false);

  const [password, setPassword] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [passwordConfirmed, setPasswordConfirmed] = useState('');

  const onSubmitForm = useCallback(async () => {
    console.log('password', password);
    console.log('passwordConfirmed', passwordConfirmed);

    try {
      setIsLoading(true);

      if (!password) {
        throw new Error('Informe a senha');
      }

      if (password.length < 6) {
        throw new Error('A senha precisa ter pelo pelo menos 6 caracteres');
      }

      if (password !== passwordConfirmed) {
        console.log('senha diferentes');
        throw new Error('A senha é diferente da confirmação!');
      }

      const user = await Auth.currentAuthenticatedUser();

      console.log('user', user);

      await Auth.changePassword(user, oldPassword, password);

      await showSuccess();

      window.location.href = '/';
    } catch (err) {
      console.log('err', err);

      Sentry.captureException(err);

      showError(err.message);
    } finally {
      setIsLoading(false);
    }
  }, [password, passwordConfirmed, oldPassword]);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
      <CssBaseline />

      <ResponsiveAppBar />

      <StatusWallet />
      <ModalMessage />

      <Container component="main" maxWidth="sm">
        <CssBaseline />

        <Box
          sx={{
            marginBottom: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
          <Typography componente="h2">Troque sua senha aqui</Typography>
          <Box component="form" noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              type="password"
              fullWidth
              required
              id="oldPassword"
              label={'Senha Atual'}
              name="oldPassword"
              autoComplete="new-password"
              className="inputForm input-account"
              value={oldPassword}
              style={inputStyle}
              onChange={(ev) => setOldPassword(ev.target.value)}
            />

            <TextField
              margin="normal"
              type="password"
              fullWidth
              required
              id="password"
              label={'Nova Senha'}
              name="password"
              autoComplete="new-password"
              className="inputForm input-account"
              value={password}
              style={inputStyle}
              onChange={(ev) => setPassword(ev.target.value)}
            />

            <TextField
              margin="normal"
              type="password"
              fullWidth
              required
              id="password-confirmed"
              label={`Confimação da senha`}
              name="password"
              autoComplete="new-password"
              className="inputForm input-account"
              value={passwordConfirmed}
              style={inputStyle}
              onChange={(ev) => setPasswordConfirmed(ev.target.value)}
            />

            <SubmitButton onSubmitForm={onSubmitForm} isLoading={isLoading} />
            <br />
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default AccountChangePassword;
