// eslint-disable-next-line no-undef

const env = window.env || process.env || {};

export const STORE_API = env.REACT_APP_STORE_API || 'https://store-api.universusgame.net';
export const IS_DEV = env.REACT_APP_IS_DEV;
export const SENTRY_DSN = env.REACT_APP_SENTRY_DSN;
export const NETWORK = env.REACT_APP_NETWORK || 80001;

export const REVEAL = '0xa9bC8c5Eb8eC7BaD1129860eD14d9Fb9C0EB07c7'; // env.REACT_APP_CONTRACT_REVEAL;
export const LOOTBOX_SALE = '0xa0F80B88A5697979b2dE393095Df5231731dfA99';
export const LOOTBOX_PROMO = '0x82D3E054Ff2ec6a085D6e6F63a350730a01E9d2D'; // ƒenv.REACT_APP_CONTRACT_LOOTBOX_PROMO;
export const ANDROID = '0xA19Ce738e3c6BA3c0815aeaF658B3640985cA0d2'; //env.REACT_APP_CONTRACT_ANDROID;

export const SPACESHIP_REVEAL =
  env.REACT_APP_SPACESHIP_REVEAL || '0xceb2d29447CE2bc9E08Ad5b660De836DA04EF8ba';

export const SPACESHIP_IQ_REVEAL =
env.REACT_APP_SPACESHIP_IQ_REVEAL || '0x9fe954cF921481ba77Eb4E849c39d831A91763Bb';

export const SPACESHIP_LOOTBOX =
  env.REACT_APP_SPACESHIP_LOOTBOX || '0x9Cf0FD7EBc18Bb6aDFA283B2cc38f44F5c7D4511';

export const SPACESHIP_IQ_LOOTBOX =
  env.REACT_APP_SPACESHIP_IQ_LOOTBOX || '0x60ba5Ee3DbD87e9a608428A6b3EC4FDA266d23E4';

export const SPACESHIP_PROMO =
  env.REACT_APP_SPACESHIP_PROMO || '0x82D3E054Ff2ec6a085D6e6F63a350730a01E9d2D';

export const SPACESHIP = env.REACT_APP_SPACESHIP || '0x6c989fE8929d5fa6C681AE3eC9A7A25f3f7e432e';

export const FIREBASE_API_KEY = env.REACT_APP_FIREBASE_API_KEY;
export const FIREBASE_AUTH_DOMAIN = env.REACT_APP_FIREBASE_AUTH_DOMAIN;
export const FIREBASE_PROJECT_ID = env.REACT_APP_FIREBASE_PROJECT_ID;
export const FIREBASE_STORAGE_BUCKET = env.REACT_APP_FIREBASE_STORAGE_BUCKET;
export const FIREBASE_MESSAGING_SENDER_ID = env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID;
export const FIREBASE_APP_ID = env.REACT_APP_FIREBASE_APP_ID;

export const API_METADATA = 'api'; // env.REACT_APP_API_METADATA;
export const OPENSEA_URL = 'https://opensea.io/assets/matic/'; // env.REACT_APP_OPENSEA_URL;

export const APP_USERPOOL_ID = env.REACT_APP_USERPOOL_ID || 'us-east-1_iOzS2BIO3'; // beta -> 'us-east-1_4nvcivslL';

export const APP_CLIENT_ID = env.REACT_APP_CLIENT_ID || '7hv26gc96gulea27nkq659fn04'; //beta -> '76ng227ralj11drl9anc0s2f1f';
