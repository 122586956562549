/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import "../style.css";

import { Grid } from "@mui/material";

import { usePrepareTicket } from "../../../../core/features/lootbox/hooks/usePrepareTicket";
import SpaceshipSimpleAndroid from "../../../components/box/SpaceshipSimpleAndroid";
import { useTicketsStore } from "../../../../core/features/lootbox/store/tickets";
import { useEffect } from "react";

export default function TicketItem({ tier, wallet }: { tier: any; wallet: string }) {
  const { isLoading, handlePrepare } = usePrepareTicket(tier, wallet);

  const { setLoading } = useTicketsStore();

  useEffect(() => {
    setLoading(isLoading);
    
    return () => setLoading(false);
  }, [isLoading, setLoading]);

  return (
    <>
      <Grid item xs={12} lg={2} md={3}>
        <SpaceshipSimpleAndroid
          tier={tier}
          isLoading={isLoading}
          onClick={() => handlePrepare()}
        />
      </Grid>
    </>
  );
}
