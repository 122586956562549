import { t } from 'i18next';
import ButtonUniversus from '../../../components/buttonUniversus';

export const SubmitButton = ({ onSubmitForm, isLoading }) => (
  <ButtonUniversus
    colorType="green-large"
    isLoading={isLoading}
    disabled={isLoading}
    customSyle={{
      display: 'flex',
      width: '100% important'
    }}
    onClick={onSubmitForm}
    className="btn-save-account">
    {t('account.save')}
  </ButtonUniversus>
);
