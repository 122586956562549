import { Box, Card, CardActions, CardContent, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import androidImg from '../../../../assets/images/android-animation.gif';
import ButtonUniversus from '../../buttonUniversus';
import { cardContentStyle } from './style';
import { useIsRevealed } from '../../../../core/features/nfts/hooks/useIsRevealed';
import unknownSpaceship from '../../../../assets/images/unknown-spaceship.png';

export default function SpaceshipSimpleAndroid({ tier, onClick, isLoading, disabled }) {
  const ref = useRef();
  const { t } = useTranslation();

  const hasMetadata = !!Object.keys(tier.metadata ?? {}).length;
  const isOpened = tier.status === 'opened';

  const { data: isRevealed } = useIsRevealed(hasMetadata && isOpened && tier.isSpaceship, {
    category: 'spaceship',
    tokenId: tier.tokenId
  });
 

  const buttonText = () => {
    if (tier.status === 'opened' && !tier.isSpaceship) {
      return 'Abrir';
    }

    if (tier.status === 'opened' && isRevealed) {
      return 'Abrir';
    }

    if (tier.status === 'pending') {
      return t('share.prepare');
    }

    if (tier.status === 'prepared') {
      return t('share.open_android');
    }

    if (!tier.metadata) {
      return 'Carregando...';
    }

    if (tier.metadata && !isRevealed) {
      return 'Revelar';
    }
  };

  const isLoadingButtonAndSpaceship = buttonText() === 'Carregando...' && tier.isSpaceship;

  const imageUrl = useMemo(() => {
    if (tier.isSpaceship && tier.metadata && !isRevealed) {
      return unknownSpaceship;
    }
    return tier.imageUrl;
  }, [tier.metadata, tier.isSpaceship, tier.imageUrl, isRevealed]);

  return (
    <Card
      sx={{
        background: 'none',
        boxShadow: 'none',
        justifyContent: 'flex-end',
        display: 'flex',
        flexDirection: 'column'
      }}
      className="card-asset">
      <CardContent key={uuidv4()} sx={cardContentStyle}>
        <Box className="container-image-android-ticket">
          {isLoadingButtonAndSpaceship ? (
            <Box
              style={{
                width: 240,
                height: 430,
                backgroundImage: `url('${imageUrl}')`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: '47% center'
              }}
            />
          ) : !tier.imageUrl ? (
            <Box
              style={{
                backgroundColor: '#efefef',
                borderRadius: 6,
                padding: 6,
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                display: 'flex',
                color: '#333'
              }}
              height="200px"
              width="100%"
              fontSize="14px">
              <Typography>Failed Loading Image</Typography>
            </Box>
          ) : (
            <img
              src={imageUrl}
              key={uuidv4()}
              loading="lazy"
              style={{
                width: '100%',
                opacity: '1'
              }}
              ref={ref}
              onError={() => {
                ref.current.src = androidImg;
              }}
              alt={tier.name}
            />
          )}
        </Box>
        <Typography className="nameBox" style={{ fontSize: '.8em' }}>
          {tier.name}
        </Typography>
      </CardContent>
      <CardActions
        sx={{
          borderRadius: '0px 0px 16px 16px',
          display: 'flex',
          justifyContent: 'center'
        }}
        key={uuidv4()}
        className="container-actions">
        <ButtonUniversus
          disabled={disabled || isLoading}
          isLoading={isLoading}
          colorType={tier.status === 'pending' || tier.status === 'prepared' ? 'blue' : 'green'}
          onClick={() => {
            console.log('deu click');

            onClick(!!isRevealed);
          }}>
          {buttonText(tier)}
        </ButtonUniversus>
      </CardActions>
    </Card>
  );
}

SpaceshipSimpleAndroid.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types, react/require-default-props
  tier: PropTypes.object,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool
};
