import { Contract } from 'ethers';
import { useCallback, useEffect, useRef, useState } from 'react';
import androidImg from '../../../../assets/images/android-animation.gif';
import { showError } from '../../../../infra/helpers/alerts';
import { params } from '../data/network-params';
import { TicketModel } from '../models/ticket.model';
import { getAndroidContract, getAndroidMetadata, getBalance , getReadProvider} from '../services';
import { useTicketsStore } from '../store/tickets';
import Sentry from './../../../../infra/sentry/index';
import delay from 'delay';
import moment from 'moment';
import { IS_DEV } from '../../../../infra/config';

export function useTicketsReady(wallet: string) {
  const [isLoading, setIsLoading] = useState(true);
  const { items, updateItems, updatedTime } = useTicketsStore();

  const disabledPending = useRef(false);

  const getTicket = useCallback(
    async (contract: Contract, tokenId: string): Promise<TicketModel> => {
      const metadata = await getAndroidMetadata(params.android, tokenId);

      const ticketModel = new TicketModel({
        metadata,
        id: tokenId,
        tokenId,
        contract,
        type: 'android',
        isSpaceship: false,
        status: 'opened',
        imageUrl: metadata?.image ?? androidImg,
        name: `${metadata?.name ?? `Android`} #${tokenId}`,
        link: metadata?.link ?? null
      });

      return ticketModel;
    },
    []
  );

  const onLoad = useCallback(async () => {
    const provider = await getReadProvider();

    const contract = getAndroidContract(provider, params.android);

    const balance = await getBalance(contract, wallet);

    const result: TicketModel[] = [];

    for (let i = 0; i < balance; i++) {
      const token = await contract.tokenOfOwnerByIndex(wallet, i);

      const tokenId = token.toNumber();

      const ticketModel = await getTicket(contract, `${tokenId}`);

      result.push(ticketModel);
    }

    return result.reverse();
  }, [wallet, getTicket]);

  useEffect(() => {
    setIsLoading(true);

    (async () => {
      try {
        console.log('vai carregar mais --------');
        const items = await onLoad();

        updateItems(items);
      } catch (err: any) {
        Sentry.captureMessage(
          JSON.stringify({
            wallet,
            action: 'loadTicketsReady'
          })
        );

        Sentry.setContext('data', {
          wallet,
          action: 'loadTicketsReady'
        });
        Sentry.captureException(err);

        if (IS_DEV) {
          showError(err.message);
        } else {
          showError();
        }
      } finally {
        setIsLoading(false);
      }
    })();

    return () => {
      updateItems([]);
    };
  }, [onLoad, updateItems, updatedTime, wallet]);

  const processQueue = useCallback(async () => {
    if (disabledPending.current) {
      return;
    }

    console.log('vai processar');

    const start = moment();

    const _items = [...useTicketsStore.getState().items];

    const pendings = _items.filter((item) => !item.metadata);

    console.log('_items', _items);
    console.log('pendings', pendings.length);

    if (!pendings.length) {
      return;
    }

    const success: TicketModel[] = [];

    for (const item of pendings) {
      const ticket = await getTicket(item.contract, item.tokenId);

      console.log('pegou ticket', ticket);

      if (ticket.metadata) {
        const index = _items.findIndex((_item) => _item.tokenId === ticket.tokenId);
        _items[index] = ticket;
        success.push(ticket);
      }
    }

    if (success.length) {
      console.log('atualiza os que deram sucesso');
      updateItems(_items);
    }

    if (success.length !== pendings.length) {
      const diff = moment(moment()).diff(start, 'seconds');

      console.log('diff segundos', diff);

      if (diff < 10) {
        await delay(10000);
      }

      console.log('vai processar queue');
      processQueue();
    }
  }, [getTicket, updateItems]);

  useEffect(() => {
    if (!isLoading) {
      disabledPending.current = false;

      processQueue();
    }

    return () => {
      disabledPending.current = true;
    };
  }, [processQueue, isLoading]);

  return {
    tiers: items,
    isLoading
  };
}
