/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-return-assign */
import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './style.css';

import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyles from '@mui/material/GlobalStyles';
import Grid from '@mui/material/Grid';
import { ThemeProvider } from '@mui/material/styles';

import { Box, Container, TextField, Typography } from '@mui/material';
import { useMetaMask } from 'metamask-react';

import { useTranslation } from 'react-i18next';

import { ModalContext } from '../../../contexts/ModalContext';
import theme from '../../../core/resources/theme';
import { showError, showSuccess } from '../../../infra/helpers/alerts';
import ResponsiveAppBar from '../../components/appbar';
import ButtonUniversus from '../../components/buttonUniversus';
import ModalMessage from '../../components/modalmessage';
import StatusWallet from '../../components/statuswallet';
import hasInvalidField from '../auth/validatorUser';
import Sentry from './../../../infra/sentry/index';

import { inputStyle } from './style';
import { checkIfInvalidFields } from './utils';
import { Name } from './form/name';
import { Phone } from './form/phone';
import { Select } from './form/select';
import { CpfInput } from './form/cpf';
import { SubmitButton } from './form/submit';
import useUser from '../../../core/features/auth/store';
import { http } from '../../../infra/http';

export const isEmpty = (property) => {
  return (
    property === '' ||
    property === null ||
    property === undefined ||
    (property.hasOwnProperty('length') && property.length === 0)
  );
};

function Account() {
  const { t } = useTranslation();

  const { toggleEnabledFun, setTier } = useContext(ModalContext);
  const { connect, account } = useMetaMask();
  const [isLoading, setIsLoading] = useState(false);

  const { user } = useUser();

  const [formAccount, setFormAccount] = useState({
    personalInfo: {}
  });

  useEffect(() => {
    setFormAccount((prev) => ({ ...prev, wallet: user.wallet || account }));
  }, [account, user.wallet]);

  useEffect(() => {
    setFormAccount({ ...user, personalInfo: user.personalInfo ?? {} });
  }, [user]);

  const onSubmitForm = useCallback(async () => {
    setIsLoading(true);

    try {
      checkIfInvalidFields({
        ...formAccount
      });

      const { data } = await http.put('v1/auth/update', {
        ...formAccount
      });

      if (data.error && !data.error?.includes('callbackWaitsForEmptyEventLoop')) {
        return showError(data.error);
      }

      await showSuccess();

      window.location.href = '/';
    } catch (err) {
      Sentry.captureException(err);
      //console.log("AQUI!!, ocorreu um erro");
      toggleEnabledFun(true);

      setTier({
        title: 'Aviso',
        message: err.message
      });
    } finally {
      setIsLoading(false);
    }
  }, [formAccount, toggleEnabledFun, setTier]);

  const connectWallet = async () => {
    console.log('Connect wallet!!');
    try {
      // if (!hasInvalidField(formAccount, false)) {
      // console.log('wait connect()');
      await connect();
    } catch (err) {
      Sentry.captureException(err);
      toggleEnabledFun(true);
      setTier({
        title: 'Aviso',
        message: err.message
      });
    }
  };

  const onChange =
    (key) =>
    ({ target: { value } }) => {
      setFormAccount((previousValue) => ({
        ...previousValue,
        [key]: value
      }));
    };

  console.log('formAccount', formAccount);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
      <CssBaseline />

      <ResponsiveAppBar />

      <StatusWallet />
      <ModalMessage />

      <Container component="main" maxWidth="sm">
        <CssBaseline />

        <Box
          sx={{
            marginBottom: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
          <Typography componente="h2">{t('account.description')}</Typography>
          <Box component="form" noValidate sx={{ mt: 1 }}>
            <Name change={onChange('name')} name={formAccount.name ?? ''} />
            <TextField
              margin="normal"
              fullWidth
              required
              id="email"
              label={t('account.email')}
              name="email"
              autoComplete="email"
              className="inputForm input-account"
              disabled
              value={formAccount.email}
              style={inputStyle}
            />
            <Phone
              change={(event) => {
                setFormAccount((prev) => ({
                  ...prev,
                  personalInfo: {
                    ...prev.personalInfo,
                    phone: event.target.value
                  }
                }));
              }}
              phone={formAccount.personalInfo?.phone ?? ''}
            />
            <CpfInput
              change={(event) => {
                setFormAccount((prev) => ({
                  ...prev,
                  personalInfo: {
                    ...prev.personalInfo,
                    document: event.target.value
                  }
                }));
              }}
              document={formAccount.personalInfo?.document ?? ''}
              country={formAccount.personalInfo?.address?.country ?? ''}
            />
            <Select
              change={(event) => {
                setFormAccount((prev) => ({
                  ...prev,
                  personalInfo: {
                    ...(prev.personalInfo ?? {}),
                    address: {
                      ...(prev.personalInfo?.address ?? {}),
                      country: event.target.value
                    }
                  }
                }));
              }}
              country={formAccount.personalInfo?.address?.country ?? ''}
            />

            <Grid
              container
              spacing={2}
              alignItems="center"
              sx={{
                mt: '10px',
                mb: '10px'
              }}>
              <Grid
                item
                md={!user.wallet ? 7 : 12}
                xs={12}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="wallet"
                  label="Wallet data"
                  name="wallet"
                  disabled
                  value={account}
                  className="inputForm input-account wallet"
                  style={{ paddingLeft: !user.wallet ? 0 : 100 }}
                  sx={{
                    background: '#9e9e9eaa',
                    '& .css-p51h6s-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled': {
                      '-webkit-text-fill-color': '#000000aa'
                    },
                    '& label': {
                      color: '#FFF'
                    }
                  }}
                />
              </Grid>
              {!user.wallet ? (
                <Grid
                  item
                  alignItems="center"
                  justifyContent="center"
                  xs={12}
                  md={5}
                  mt={1}
                  display="flex">
                  <ButtonUniversus
                    colorType="green"
                    onClick={connectWallet}
                    disabled={isLoading || !!user.wallet}
                    className="btn-connect-wallet"
                    style={{
                      fontSize: '.7em !important'
                    }}>
                    {isEmpty(formAccount?.wallet)
                      ? t('account.connect_wallet')
                      : t('account.connected')}
                  </ButtonUniversus>
                </Grid>
              ) : null}
            </Grid>

            <SubmitButton onSubmitForm={onSubmitForm} isLoading={isLoading} />
            <br />
          </Box>
        </Box>
      </Container>

      {/* Footer */}
      {/* <Footer /> */}
    </ThemeProvider>
  );
}

export default Account;
