import PropTypes from "prop-types";
import HeaderImage from "../../../assets/images/header.webp";

export default function Logo({ width = "80%", marginBottom = "0px" }) {
  return (
    <img
      src={HeaderImage}
      srcSet={HeaderImage}
      alt="Universus icon"
      style={{ width, marginBottom }}
    />
  );
}

Logo.propTypes = {
  width: PropTypes.string,
  marginBottom: PropTypes.string,
};
