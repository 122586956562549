/* eslint-disable @typescript-eslint/no-explicit-any */
import { IS_DEV } from '../../../../infra/config';
import { useCallback, useState } from 'react';
import { showError, showSuccess } from '../../../../infra/helpers/alerts';
import Sentry from '../../../../infra/sentry/index';
import { TicketModel } from '../../lootbox/models/ticket.model';
import { useTicketsStore } from '../../../../core/features/lootbox/store/tickets';

export function useSpaceshipPrepareTicket(ticket: TicketModel, wallet: string) {
  const [isLoading, setIsLoading] = useState(false);
  const { updateItems } = useTicketsStore();

  const handlePrepare = useCallback(async () => {
    try {
      console.log('prepare spaceship ticket', ticket);

      setIsLoading(true);

      const prepare = await ticket.prepare();

      console.log('result wait prepare', prepare);

      setTimeout(() => {
        updateItems((prev) => {
          const news = prev.filter(({ tokenId }) => tokenId !== ticket.tokenId);

          console.log('ticket tokenId', ticket.tokenId);
          console.log('news', news);

          return news;
        });
      }, 300);

      showSuccess();
    } catch (err: any) {
      Sentry.captureException(err);
      Sentry.setContext('ticket', { ticket, wallet, action: 'prepare' });

      if (err.message?.includes('estimate gas')) {
        showError(
          'Não foi possível estimar o gas necessário para executar a transação. Por favor tente novamente em alguns segundos.'
        );
      } else {
        if (IS_DEV) {
          showError(err.message);
        } else {
          showError();
        }
      }
    } finally {
      setIsLoading(false);
    }
  }, [ticket, wallet, updateItems]);

  return {
    handlePrepare,
    isLoading
  };
}
