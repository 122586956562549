/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/prop-types */
import './index.css';

export default function ButtonUniversus({
  colorType = '',
  children = <></>,
  isLoading = false,
  onClick = () => {},
  disabled = false,
  fontSize = '18px/14px',
  customSyle = {}
}) {
  const mode = colorType !== '' ? `btn-universus-${colorType}` : 'btn-universus-blue';
  return (
    <div
      className={`btn-universus ${mode}${disabled ? ' disabled' : ''}`}
      style={{ fontSize, ...customSyle }}
      onClick={!disabled && !isLoading ? onClick : undefined}>
      {isLoading ? 'Carregando....' : children}
    </div>
  );
}
