import create from "zustand";
import { TicketModel } from "../models/ticket.model";

type UpdateFnParams = (prevItems: TicketModel[]) => TicketModel[];
type UpdateParams = TicketModel[] | UpdateFnParams;

export interface TicketsStore {
  items: TicketModel[];
  isLoading: boolean;
  updatedTime: number;
  refresh: () => any;
  updateItems: (params: UpdateParams) => any;
  setLoading: (v: boolean) => any;
}

export const useTicketsStore = create<TicketsStore>((set) => ({
  items: [],
  isLoading: false,
  updatedTime: +new Date(),
  refresh: () =>
    set(() => ({
      updatedTime: +new Date(),
    })),
  setLoading: (next: boolean) =>
    set(() => ({
      isLoading: next,
    })),
  updateItems: (params: UpdateParams) =>
    set((state) => ({
      items: typeof params === "function" ? params(state.items) : params,
    })),
}));
