import { cpf as cpfValidator } from 'cpf-cnpj-validator';

/**
 * Check if there are invalid fields in the given account form object.
 * If there are, throw an error with a message in Portuguese.
 * If the country is Brazil, also check if the CPF and phone fields are valid.
 * @param {Object} formAccount - The account form object to check.
 * @throws {Error} If there are invalid fields or invalid CPF/phone in Brazil.
 */
export const checkIfInvalidFields = (formAccount) => {
  if (!formAccount.personalInfo?.document) {
    throw new Error('Preencha o documento');
  }

  if (formAccount.personalInfo?.address?.country === 'Brazil') {
    if (!cpfValidator.isValid(formAccount.personalInfo?.document)) {
      throw new Error('Preencha o campo com um CPF válido');
    }
    if (formAccount.personalInfo?.phone?.length < 11) {
      throw new Error('Preencha o campo com um número de celular válido, DDD+Número');
    }
  }
};
