/* eslint-disable react/prop-types */
import '../style.css';

import { Grid } from '@mui/material';

import SpaceshipSimpleAndroid from '../../../components/box/SpaceshipSimpleAndroid';

import { useTranslation } from 'react-i18next';
import EmptyTicketList from '../../../components/EmptyTicketList';
import { useSpaceshipTicketsPrepareds } from '../../../../core/features/spaceship/hooks/useSpaceshipTicketsPrepareds';
import { useSpaceshipOpenTicket } from '../../../../core/features/spaceship/hooks/useSpaceshipOpenTicket';

export default function TabSpaceshipPrepared(props) {
  const { t } = useTranslation();
  const { items, isLoading } = useSpaceshipTicketsPrepareds(props.wallet);
  const { handleOpen, isLoading: isLoadingReady } = useSpaceshipOpenTicket(props.wallet);

  return (
    <>
      {items.map((tier) => (
        <Grid item key={tier.requestId} xs={12} lg={2} md={3}>
          <SpaceshipSimpleAndroid
            tier={tier}
            isLoading={isLoadingReady}
            onClick={() => handleOpen(tier)}
          />
        </Grid>
      ))}

      {isLoading ? (
        <Grid item xs={12} lg={12} md={12}>
          <center>
            <h2 className="font-bold" style={{ marginTop: '100px' }}>
              {t('tickets.loading')}
            </h2>
          </center>
        </Grid>
      ) : null}

      {!isLoading && items.length === 0 && <EmptyTicketList tab="prepared" />}
    </>
  );
}
