/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import '../style.css';

import { Grid } from '@mui/material';

import SpaceshipSimpleAndroid from '../../../components/box/SpaceshipSimpleAndroid';
import { useSpaceshipPrepareTicket } from '../../../../core/features/spaceship/hooks/useSpaceshipPrepareTicket';
// import { useSpaceshipShowRevel } from '../../../../core/features/spaceship/hooks/useSpaceshipShowRevel';

export default function TicketItem({ tier, wallet }: { tier: any; wallet: string }) {
  const { isLoading, handlePrepare } = useSpaceshipPrepareTicket(tier, wallet);
  // const { handleRun } = useSpaceshipShowRevel();

  return (
    <>
      <Grid item xs={12} lg={2} md={3}>
        <SpaceshipSimpleAndroid tier={tier} isLoading={isLoading} onClick={() => handlePrepare()} />
      </Grid>
    </>
  );
}
