/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState } from 'react';

import './Login.css';

import { Tab, Tabs } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import { ThemeProvider } from '@mui/material/styles';

import SubHeaderImage from '../../../assets/images/subheader.webp';
import WhatsappImage from '../../../assets/images/whatsapp.webp';
import theme from '../../../core/resources/theme';
import Logo from '../../components/logo';
import Register from '../register';
import TabPanel from './tab-panel';
import LoginTab from './components/LoginTab';
import { useNavigate, useParams } from 'react-router-dom';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

function Login() {
  const navigate = useNavigate();
  const promoCode = useParams()?.promoCode ?? localStorage.getItem("promoCode")

  const [value, setValue] = useState(useParams()?.promoCode ? 1 : 0);
  
  useEffect(() => {
    if (promoCode) {
      localStorage.setItem("promoCode", promoCode)
      navigate("/auth/login")
    }
  }, [promoCode])

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container
        component="main"
        maxWidth="xl"
        className="container-login"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          height: '100vh'
        }}>
        <CssBaseline />

        <Grid
          container
          spacing={2}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '90%'
          }}>
          <Grid md={4} xs={12} item sx={{ display: 'flex', marginTop: '0px' }}>
            <Box item sx={{ width: '100%' }}>
              <Box
                marginTop="20px"
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column'
                }}>
                <Logo marginBottom="10px" />
                <img
                  src={SubHeaderImage}
                  srcSet={SubHeaderImage}
                  alt="Universus icon"
                  style={{ width: '25%' }}
                />
              </Box>

              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: 'divider',
                  marginTop: '5vh'
                }}
                className="tab-panel-login"
                item>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  className="tab-panel-login"
                  md={12}>
                  <Tab
                    label="Login"
                    {...a11yProps(0)}
                    className="tab-panel-login-btn"
                    md={6}
                    xs={12}
                  />
                  <Tab
                    label="Sign up"
                    {...a11yProps(1)}
                    className="tab-panel-login-btn"
                    md={6}
                    xs={12}
                  />
                </Tabs>
              </Box>

              <TabPanel value={value} index={0}>
                <Box
                  noValidate
                  sx={{
                    mt: 1,
                    height: '100%',
                    width: '100%',
                    padding: '0px !important'
                  }}>
                  <LoginTab onResetPassword={() => navigate('/auth/forgot')} />
                </Box>
              </TabPanel>

              <TabPanel value={value} index={1}>
                <Register />
              </TabPanel>
            </Box>
          </Grid>

          <Grid md={8} xs={12} item className="ninjas-background-div">
            <img
              src={'/images/pt-br/androids-crop.webp'}
              srcSet={'/images/pt-br/androids-crop.webp'}
              alt="Universus icon"
              style={{ width: 'auto', height: '100%', display: 'none' }}
              className="ninjas-background"
            />
          </Grid>
        </Grid>
      </Container>

      <a
        href="https://api.whatsapp.com/message/V62YAKBRVJXBH1?autoload=1&app_absent=0"
        target="_blank"
        rel="noopener noreferrer">
        <img
          src={WhatsappImage}
          srcSet={WhatsappImage}
          alt="Universus icon"
          style={{ width: 'auto', height: '100px' }}
          className="whatsapp-icon"
        />
      </a>
    </ThemeProvider>
  );
}

export default Login;
