import { useCallback, useContext } from 'react';
import { getSpaceshipMetadata } from '../services';
import { WebGlContextProvider } from '../../../../contexts/WebGLContext';
import { hideLoading, showLoading } from '../../../../infra/helpers/alerts';

export function useSpaceshipShowRevel() {
  const { sendMessage } = useContext(WebGlContextProvider);

  const handleRun = useCallback(
    async (tokenId: string) => {
      showLoading();

      try {
        const result = await getSpaceshipMetadata(tokenId);

        console.log('vai inserir metadata', result);

        if (result) {
          const albedo1 = result.albedo1;
          const albedo2 = result.albedo2;
          const albedo3 = result.albedo3;
          const rarity = result.rarity;
          const emission = result.emission;
          const spaceship = result.category;

          sendMessage({
            spaceship: spaceship === 'glide' ? 'glyde' : spaceship,
            rarity,
            albedo1,
            albedo2,
            albedo3,
            emission
          });
        }
      } catch (err) {
      } finally {
        hideLoading();
      }
    },
    [sendMessage]
  );

  return { handleRun };
}
