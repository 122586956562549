/* eslint-disable react/prop-types */

import '../style.css';

import { Grid } from '@mui/material';

import { useTranslation } from 'react-i18next';
import EmptyTicketList from '../../../components/EmptyTicketList';
import TicketItem from '../components-spaceship/TicketItem';
import { useSpaceshipTickets } from '../../../../core/features/spaceship/hooks/useSpaceshipTickets';

export default function TabSpaceshipTickets(props) {
  const { t } = useTranslation();
  const { isLoading, tiers } = useSpaceshipTickets(props.wallet);

  return (
    <>
      {tiers.map((tier) => (
        <TicketItem key={tier.id} tier={tier} />
      ))}

      {isLoading ? (
        <Grid item xs={12} lg={12} md={12}>
          <center>
            <h2 className="font-bold" style={{ marginTop: '100px' }}>
              {t('tickets.loading')}
            </h2>
          </center>
        </Grid>
      ) : null}

      {!isLoading && tiers.length === 0 && <EmptyTicketList tab="tickets" />}
    </>
  );
}
