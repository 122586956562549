import { IS_DEV } from '../../../../infra/config';
import { useCallback, useEffect, useState } from 'react';

import { showError } from '../../../../infra/helpers/alerts';
import Sentry from '../../../../infra/sentry';
import { getReadProvider } from '../../lootbox/services';
import { TicketModel } from '../../lootbox/models/ticket.model';
import { getSpaceshipIqLootboxContract, getSpaceshipLootboxContract, getSpaceshipLootboxPromoContract } from '../services';
import axios from 'axios';
import delay from 'delay';
import { useTicketsStore } from '../../../../core/features/lootbox/store/tickets';

export function useSpaceshipTickets(wallet: string) {
  const [isLoading, setIsLoading] = useState(false);
  const { items, updatedTime } = useTicketsStore();

  const onLoad = useCallback(async (): Promise<TicketModel[]> => {
    const provider = getReadProvider();

    const contract = getSpaceshipLootboxContract(provider);
    const contractIq = getSpaceshipIqLootboxContract(provider);
    const contractPromo = getSpaceshipLootboxPromoContract(provider);


    const tickets: TicketModel[] = [];

    let balanceBoxSale = 0;

    try {
      console.log('wallet --> ', wallet);

      const _balanceOf = await contract.balanceOf(wallet);
      const _balanceOfIq = await contractIq.balanceOf(wallet);
      const _balanceOfPromo = await contractPromo.balanceOf(wallet);
      
      console.log('balanceOf', _balanceOf);
      console.log('_balanceOfIq', _balanceOfIq);
      console.log('_balanceOfPromo', _balanceOfPromo);

      const _balanceValue = _balanceOf.toNumber();
      const _balancePromoValue = _balanceOfPromo.toNumber();
      const _balanceIqValue = _balanceOfIq.toNumber();

      console.log('balance value', _balanceValue);

      for (let i = 0; i < _balanceValue; i++) {
        console.log('index', i);

        const token = await contract.tokenOfOwnerByIndex(wallet, i);

        const tokenId = token.toNumber();

        console.log('token', token);

        console.log('tokenId', tokenId);

        const metadataUrl = await contract.tokenURI(tokenId);

        console.log('metadataUrl', metadataUrl);

        Sentry.setContext(`token-${i}`, {
          index: i,
          token,
          tokenId,
          metadataUrl
        });

        const ticket = new TicketModel({
          id: i,
          contract: contract,
          type: 'sale',
          tokenId: `${tokenId}`,
          isSpaceship: true,
          imageUrl: null,
          name: `Spaceship box`,
          status: 'pending'
        });

        try {
          const { data: metadata } = await axios.get(`${metadataUrl}`);

          console.log('metadata', metadata);

          ticket.imageUrl = metadata.image;
          ticket.name = `${metadata.name}`;

        } catch (err) {
          console.log('erro ao carregar os meetadata')
        }

        tickets.push(
          ticket
        );
      }

      for (let i = 0; i < _balanceIqValue; i++) {
        console.log('index', i);

        const token = await contractIq.tokenOfOwnerByIndex(wallet, i);

        const tokenId = token.toNumber();

        console.log('token', token);

        console.log('tokenId', tokenId);

        const metadataUrl = await contractIq.tokenURI(tokenId);

        console.log('metadataUrl', metadataUrl);

        Sentry.setContext(`token-${i}`, {
          index: i,
          token,
          tokenId,
          metadataUrl
        });

        const ticket = new TicketModel({
          id: i,
          contract: contractIq,
          type: 'iq',
          tokenId: `${tokenId}`,
          isSpaceship: true,
          imageUrl: null,
          name: `Spaceship box`,
          status: 'pending'
        });

        try {
          const { data: metadata } = await axios.get(`${metadataUrl}`);

          console.log('metadata', metadata);

          ticket.imageUrl = metadata.image;
          ticket.name = `${metadata.name}`;

        } catch (err) {
          console.log('erro ao carregar os meetadata')
        }

        tickets.push(
          ticket
        );
      }

      console.log('vai space promo', _balancePromoValue);

      for (let i = 0; i < _balancePromoValue; i++) {
        console.log('index promo', i);

        const token = await contractPromo.tokenOfOwnerByIndex(wallet, i);

        console.log('token promo', token);

        const tokenId = token.toNumber();

        console.log('tokenId promo', tokenId);

        const metadataUrl = await contractPromo.tokenURI(tokenId);

        console.log('metadataUrl promo', metadataUrl);

        const { data: metadata } = await axios.get(`${metadataUrl}`);

        console.log('metadata', metadata);

        tickets.push(
          new TicketModel({
            id: i,
            contract: contractPromo,
            type: 'promo',
            tokenId: `${tokenId}`,
            isSpaceship: true,
            imageUrl: metadata.image,
            name: `${metadata.name} Promo`,
            status: 'pending'
          })
        );
      }

    } catch (err: any) {
      console.log('erro ao abrir nave', err, err.message);


      Sentry.setContext('data', {
        wallet,
        action: 'loadTickets',
        balanceBoxSale
      });
      Sentry.captureException(err);

      if (IS_DEV) {
        showError(err.message);
      } else {
        showError();
      }
    }

    const result = tickets.sort((a, b) => b.id.toString().localeCompare(a.id.toString())).reverse();

    return result;
  }, [wallet]);

  console.log('updatedTime', updatedTime);

  useEffect(() => {
    const { updateItems } = useTicketsStore.getState();

    (async () => {
      await delay(500);

      console.log(
        '=== ====== === ====== === ====== === ====== vai chamar on load === ====== === ====== === ====== === ====== === ====== '
      );

      setIsLoading(true);

      const nfts = await onLoad();

      updateItems(nfts);

      setIsLoading(false);
    })();

    return () => {
      updateItems([]);
    };
  }, [onLoad, updatedTime]);

  return {
    tiers: items,
    isLoading
  };
}
