/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react';

import './style.css';

import { Container, Grid } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyles from '@mui/material/GlobalStyles';
import { ThemeProvider } from '@mui/material/styles';

import { useMetaMask } from 'metamask-react';
import theme from '../../../core/resources/theme';
import ResponsiveAppBar from '../../components/appbar';
import StatusWallet from '../../components/statuswallet';
import TabsTickets from './components/Tabs';
import TabPrepared from './tabs/prepared';
import TabReady from './tabs/ready';
import TabTickets from './tabs/tickets';
import { Box } from '@mui/system';
import TabSpaceshipTickets from './tabs-spaceship/spaceship-tickets';
import TabSpaceshipPrepared from './tabs-spaceship/spaceship-prepared';
import TabSpaceshipReady from './tabs-spaceship/spaceship-ready';

export default function Tickets() {
  const { account: wallet, status } = useMetaMask();

  const [tabRoot, setTabRoot] = useState(0);
  const [tab, setTab] = useState('pending');

  const setNewTab = (value) => {
    setTab(value);
  };

  return (
      <ThemeProvider theme={theme}>
        <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
        <CssBaseline />

        <ResponsiveAppBar />

        <StatusWallet />

        <Container component="main" maxWidth="xl">
          <Box display="flex" gap="16px" flexDir="row">
            <Box
              variant="contained"
              onClick={() => setTabRoot(0)}
              className={`btn-universus btn-universus-${tabRoot === 0 ? 'green' : 'blue'}`}>
              Android
            </Box>

            <Box
              variant="contained"
              onClick={() => setTabRoot(1)}
              className={`btn-universus btn-universus-${tabRoot === 1 ? 'green' : 'blue'}`}>
              Naves
            </Box>
          </Box>

          <TabsTickets value={tab} onChange={setNewTab} />

          {tabRoot === 0 && wallet && status === 'connected' ? (
            <Grid container spacing={1} direction="row">
              {tab === 'pending' ? <TabTickets wallet={wallet} /> : null}

              {tab === 'prepared' ? <TabPrepared wallet={wallet} /> : null}

              {tab === 'opened' ? <TabReady wallet={wallet} /> : null}
            </Grid>
          ) : null}

          {tabRoot === 1 && wallet && status === 'connected' ? (
            <Grid container spacing={1} direction="row">
              {tab === 'pending' ? <TabSpaceshipTickets wallet={wallet} /> : null}

              {tab === 'prepared' ? <TabSpaceshipPrepared wallet={wallet} /> : null}

              {tab === 'opened' ? <TabSpaceshipReady wallet={wallet} /> : null}
            </Grid>
          ) : null}
        </Container>
      </ThemeProvider>
  );
}
