import { useMutation } from 'react-query';
import { http } from '../../../../infra/http';
import { TransferoCreatePixRequest, TransferoCreatePixResponse } from '../types';

export function useTransactionPix() {
  return useMutation(async (body: TransferoCreatePixRequest) => {
    const { data } = await http.post('/v1/transaction', body);

    return data as TransferoCreatePixResponse;
  });
}
