/* eslint-disable react/require-default-props */
import PropTypes from "prop-types";

import { createContext, useCallback, useMemo, useState } from "react";

export const StatusWalletContext = createContext(false);

// eslint-disable-next-line react/prop-types
export function StatusWalletProvider({ children }) {
  // the value that will be given to the context
  const [comeBack, toggleComeBack] = useState(false);
  const [wallet, walletConnected] = useState(false);
  const [isDashboard, setIsDashboardFun] = useState(false);

  const toggleComeBackEnabled = useCallback(
    () => toggleComeBack(!comeBack),
    [comeBack]
  );
  const setIsDashboard = useCallback((val) => setIsDashboardFun(val), []);

  const StatusWalletContextValue = useMemo(
    () => ({
      comeBack,
      toggleComeBackEnabled,
      walletConnected,
      wallet,
      isDashboard,
      setIsDashboard,
    }),
    [
      comeBack,
      toggleComeBackEnabled,
      walletConnected,
      wallet,
      isDashboard,
      setIsDashboard,
    ]
  );

  return (
    // the ModalContextProvider gives access to the context to its children
    <StatusWalletContext.Provider value={StatusWalletContextValue}>
      {children}
    </StatusWalletContext.Provider>
  );
}

StatusWalletProvider.propTypes = {
  children: PropTypes.node,
};

export default StatusWalletProvider;
