/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types';
import { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';

import { NETWORK, STORE_API } from '../infra/config';
import useUser from '../core/features/auth/store';
import { AppContext } from '../App';
import { Auth } from '@aws-amplify/auth';
import { http } from '../infra/http';
import { useMetaMask } from 'metamask-react';
import { showConfirm, showWarning } from '../infra/helpers/alerts';
import delay from 'delay';
import { createBrowserRouter, useLocation } from 'react-router-dom';

export const AuthContext = createContext({});

export function AuthContextProvider({ children }) {
  const [isLoading, setIsLoading] = useState(true);
  const [isLogged, setIsLogged] = useState(false);

  const isChaingNetwork = useRef(false);

  const { chainId, switchChain } = useMetaMask();

  const { setLoadingApp } = useContext(AppContext);

  useEffect(() => {
    (async () => {
      try {
        const session = await Auth.currentSession();

        if (session) {
          setIsLogged(true);
        }
      } catch (err) {
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (isLoading) {
      console.log('esta carregando auth');

      return;
    }

    if (!isLogged) {
      console.log('nao esta logado');

      setLoadingApp(false);
      return;
    }

    (async () => {
      try {
        const { setUser } = useUser.getState();

        const { data } = await http.get(`${STORE_API}/v1/auth/me`);

        setUser(data);
      } catch (err) {
      } finally {
        console.log('loading false');

        setLoadingApp(false);
      }
    })();
  }, [isLoading, isLogged, setLoadingApp]);

  const handleVerifyNetwork = useCallback(async () => {
    console.log('vai verificar se a network ta diferente');
    const networkId = Number(NETWORK);

    console.log('esta na ', parseInt(chainId, 16));
    console.log('deveria ta na ', networkId);

    const atual = parseInt(chainId, 16);

    if (!isNaN(atual) && atual !== networkId && !isChaingNetwork.current) {
      isChaingNetwork.current = true;

      await delay(5000);

      console.log('ta diferente');

      await showWarning(
        'Você esta na rede blockchain incorreta, para continuar navegando realize a troca de rede'
      );

      try {
        await switchChain(`0x${networkId.toString(16)}`);
        console.log('trocou!!!! vai da reload');
        window.location.reload();
      } catch (err) {
      } finally {
        isChaingNetwork.current = false;
      }
    }
  }, [chainId, switchChain]);

  const location = useLocation();

  useEffect(() => {
    if (isLogged) {
      handleVerifyNetwork();
    }
  }, [isLogged, handleVerifyNetwork, location]);

  return <AuthContext.Provider>{children}</AuthContext.Provider>;
}

export const useAuthContext = () => {
  const context = useContext(AuthContext);
  return context;
};

AuthContextProvider.propTypes = {
  children: PropTypes.node
};
