import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { en } from "./en";
import { pt } from "./pt-br";

let currentLang = localStorage.getItem("translate");

if (!currentLang && navigator.language.includes("pt")) {
  currentLang = "pt-br";
}

console.log('currentLang', currentLang);

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: en,
      },
      pt: {
        translation: pt,
      },
    },
    lng: currentLang || "en",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });
