import { IS_DEV } from '../../../../infra/config';
import { useCallback, useEffect, useState } from 'react';

import androidImg from '../../../../assets/images/android-animation.gif';
import { showError } from '../../../../infra/helpers/alerts';
import { params } from '../data/network-params';
import { TicketModel } from '../models/ticket.model';
import { getBalance, getLootboxContract, getReadProvider } from '../services';
import { useTicketsStore } from '../store/tickets';
import Sentry from './../../../../infra/sentry/index';

export function useTickets(wallet: string) {
  const [isLoading, setIsLoading] = useState(false);
  const { items, updateItems, updatedTime } = useTicketsStore();

  const onLoad = useCallback(async (): Promise<TicketModel[]> => {
    const provider = await getReadProvider();

    const lootBoxSale = getLootboxContract(provider, params.lootBoxSale);
    const lootBoxPromo = getLootboxContract(provider, params.lootBoxPromo);

    const tickets: TicketModel[] = [];

    let balanceBoxSale = 0;
    let balanceBoxPromo = 0;

    try {
      balanceBoxSale = await getBalance(lootBoxSale, wallet);
      console.log('deu balanceof do boxsale')
      balanceBoxPromo = await getBalance(lootBoxPromo, wallet);
      console.log('deu balanceof box promo')

      for (let i = 0; i < balanceBoxSale; i++) {
        const token = await lootBoxSale.tokenOfOwnerByIndex(wallet, i);
        const tokenId = token.toNumber();

        tickets.push(
          new TicketModel({
            id: i,
            contract: lootBoxSale,
            type: 'sale',
            tokenId: `${tokenId}`,
            isSpaceship: false,
            imageUrl: androidImg,
            name: `Android #${tokenId}`,
            status: 'pending'
          })
        );
      }

      console.log('balanceBoxPromo', balanceBoxPromo);

      for (let i = 0; i < balanceBoxPromo; i++) {
        const token = await lootBoxPromo.tokenOfOwnerByIndex(wallet, i);
        const tokenId = token.toNumber();

        const tierType = await lootBoxPromo.tokenTierMap(tokenId);

        console.log('tierType', tierType);

        // Tier Promo
        if (tierType === 1) {
          const id = balanceBoxSale + i;

          tickets.push(
            new TicketModel({
              id,
              contract: lootBoxPromo,
              type: 'promo',
              isSpaceship: false,
              imageUrl: androidImg,
              tokenId: `${tokenId}`,
              name: `Android promo #${tokenId}`,
              status: 'pending'
            })
          );
        }
      }
    } catch (err: any) {
      Sentry.captureMessage(
        JSON.stringify({
          wallet,
          action: 'loadTickets',
          balanceBoxSale,
          balanceBoxPromo
        })
      );

      Sentry.setContext('data', {
        wallet,
        action: 'loadTickets',
        balanceBoxSale,
        balanceBoxPromo
      });
      Sentry.captureException(err);

      if (IS_DEV) {
        showError(err.message);
      } else {
        showError();
      }
    }

    const result = tickets.sort((a, b) => b.id.toString().localeCompare(a.id.toString())).reverse();

    return result;
  }, [wallet]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      const nfts = await onLoad();

      updateItems(nfts);

      setIsLoading(false);
    })();

    return () => {
      updateItems([]);
    };
  }, [onLoad, updateItems, updatedTime]);

  return {
    tiers: items,
    isLoading
  };
}
