import { Typography, Box } from '@mui/material';
import './style.css';
const MiniContainerAndroid = ({ type = 'new', children }) => {
  return (
    <>
      {type === 'new' ? (
        <Box
          height="50px"
          position="relative"
          display="flex"
          alignItems="center"
          marginBottom="25px"
          justifyContent="center"
          p={2}
          borderRadius="10px"
          border="1px solid #1DFDFF"
          sx={{ cursor: 'pointer' }}>
          <img
            src="/images/card_new_pack.png"
            alt=""
            style={{
              position: 'absolute',
              left: 2,
              top: -50,
              width: '80px'
            }}
          />
          <Typography
            fontFamily="Anek Latin"
            sx={{ width: 160 }}
            fontWeight="bold"
            textAlign="center"
            fontSize="md"
            color="#FFF">
            Journey to the Unknown PACK
          </Typography>
        </Box>
      ) : (
        <Box
          height="40px"
          position="relative"
          display="flex"
          alignItems="center"
          justifyContent="center"
          p={2}
          borderRadius="10px"
          marginTop="10px"
          border="1px solid #1DFDFF"
          sx={{ cursor: 'not-allowed' }}>
          <img
            src="/images/card_pack.png"
            alt=""
            style={{
              position: 'absolute',
              left: 2,
              width: '50px'
            }}
          />
          <Typography
            fontWeight="bold"
            textAlign="center"
            fontSize="md"
            fontFamily="Anek Latin"
            className="textBlur">
            Next PACK coming soon
          </Typography>
        </Box>
      )}
    </>
  );
};

export { MiniContainerAndroid };
