import { IS_DEV } from '../../../../infra/config';
import { useState } from 'react';
import { showError, showSuccess } from '../../../../infra/helpers/alerts';
import Sentry from '../../../../infra/sentry';
import { TicketModel } from '../../lootbox/models/ticket.model'; 
import { useTicketsStore } from '../../../../core/features/lootbox/store/tickets';

export function useSpaceshipOpenTicket(wallet: string) {
  const [isLoading, setIsLoading] = useState(false);
  const { items, updateItems } = useTicketsStore();

  const handleOpen = async (ticket: TicketModel) => {
    setIsLoading(true);

    try {
      await ticket.open();

      const filtereds = items.filter((item) => item.requestId !== ticket.requestId);

      updateItems(filtereds);

      showSuccess();

      return;
    } catch (err: any) {
      Sentry.captureException(err);
      Sentry.setContext('ticket', { ticket, wallet, action: 'open' });
      if (IS_DEV) {
        showError(err.message);
      } else {
        showError();
      }
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    handleOpen
  };
}
