/* eslint-disable no-nested-ternary */
import { Grid, Input, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CloseModalImage from '../../../assets/images/close-modal.webp';
import CriptoIconImage from '../../../assets/images/cripto-icon.webp';
import PixLogoImage from '../../../assets/images/pix-logo.webp';
import raritiesColors from '../../../core/resources/theme/colors';
import ButtonUniversus from '../buttonUniversus';
import { useQuote } from '../../../core/features/transactions/hooks/useQuote';
import { Box } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

export default function ShowModal({ onPay, product, onClose, onChange }) {
  const { t } = useTranslation();

  const { isLoading, data: quote } = useQuote(
    { productId: product.id, quantity: product.quantity },
    false
  );

  const [styleModal, setStyleModal] = useState('containerSpaceShipImage');

  useEffect(() => {
    if (product.category === 'android') {
      setStyleModal('containerAndroidImageGroup');
    } else if (product.category === 'spaceship') {
      setStyleModal('containerSpaceShipImage');
    } else {
      setStyleModal('containerAndroidImage');
    }
  }, [product.category]);

  return (
    <Grid className="containerModal">
      <div class="containerModalImage">
        <img src={product.imageUrl} alt={product.name} className={styleModal} />
      </div>
      <div className="containerModalContent">
        <Grid
          item
          md={11}
          className="modalContent"
          justifyContent="flex-start"
          alignItems="flex-start">
          <br />
          <Typography
            id="transition-modal-title"
            variant="h6"
            component="h2"
            style={{ textTransform: 'capitalize' }}
            className="font-bold">
            {product.name}
          </Typography>
          <Typography component="p" sx={{ mt: 2 }}>
            Uma caixa {product.name} possui como{' '}
            <strong>
              chances de{' '}
              {product.category === 'spaceship'
                ? 'naves'
                : product.category === 'android'
                ? 'androids'
                : ''}
              :
            </strong>
          </Typography>
          <Grid container sx={{ mt: 2 }}>
            <Grid item md={4} xs={6}>
              <Typography>
                <strong>
                  {t('tickets.modal.buy.common')}: {product.dropRates.common}%
                </strong>
              </Typography>
            </Grid>
            <Grid item md={4} xs={6}>
              <Typography color={raritiesColors.exotic}>
                <strong>
                  {t('tickets.modal.buy.exotic')}: {product.dropRates.exotic}%
                </strong>
              </Typography>
            </Grid>
            <Grid item md={4} xs={6} color={raritiesColors.epic}>
              <Typography>
                <strong>
                  {t('tickets.modal.buy.epic')}: {product.dropRates.epic}%
                </strong>
              </Typography>
            </Grid>
            <Grid item md={4} xs={6} color={raritiesColors.unusual}>
              <Typography>
                <strong>
                  {t('tickets.modal.buy.unusual')}: {product.dropRates.uncommon}%
                </strong>
              </Typography>
            </Grid>
            <Grid item md={4} xs={6} color={raritiesColors.rare}>
              <Typography>
                <strong>
                  {t('tickets.modal.buy.rare')}: {product.dropRates.rare}%
                </strong>
              </Typography>
            </Grid>
            <Grid item md={4} xs={6} color={raritiesColors.mythical}>
              <Typography>
                <strong>
                  {t('tickets.modal.buy.mythical')}: {product.dropRates.mythic}%
                </strong>
              </Typography>
            </Grid>
          </Grid>
          <br />
          {/* <Typography className="priceEther">${tier.priceETH} WETH</Typography> */}
          {/* <Typography className="priceEther"></Typography> */}

          <Box w="70px">
            <Input
              type="number"
              onChange={(v) => {
                onChange('quantity', v.target.value);
              }}
              value={product.quantity || 1}
              min={1}
            />
          </Box>

          <Typography sx={{ color: 'text.secondary', mt: 1 }} component="p">
            {!isLoading && quote?.price > 0 ? (
              quote?.price.toFixed(2)
            ) : (
              <> {t('tickets.modal.buy.loading')}</>
            )}

            {!isLoading && quote?.price > 0 ? <> BRL</> : ''}
          </Typography>
          <br />

          <Grid container spacing={1}>
            <Grid item md={6} sm={12} xs={12}>
              <ButtonUniversus
                colorType={'green-large'}
                isLoading={isLoading}
                onClick={() => {
                  if (!isLoading) {
                    onPay(product);
                  }
                }}
                // disabled={priceConverted === false}
                customSyle={{ width: '100%', paddingLeft: 5, paddingRight: 5 }}>
                <img
                  src={PixLogoImage}
                  style={{ width: '20px', marginRight: '5px' }}
                  alt={product.name}
                />
                <Typography fontWeight={'bold'} fontSize={'15px'} mt={'3px'} lineHeight={'16px'}>
                  {t('tickets.modal.buy.buttonPix')}
                </Typography>
              </ButtonUniversus>
            </Grid>
            <Grid item md={6} sm={12} sx={12}>
              <a href="https://crypto-sales.web.app/" target="_blank" rel="noreferrer">
                <ButtonUniversus
                  colorType={'green-large'}
                  isLoading={isLoading}
                  customSyle={{ width: '100%', paddingLeft: 5, paddingRight: 5 }}>
                  <img
                    src={CriptoIconImage}
                    style={{ width: '20px', marginRight: '5px' }}
                    alt={product.name}
                  />
                  <Typography fontWeight="bold" fontSize="15px" mt="3px" lineHeight="16px">
                    {t('tickets.modal.buy.buttonCripto')}
                  </Typography>
                </ButtonUniversus>
              </a>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          md={1}
          className="closeModal"
          style={{
            position: 'absolute',
            top: 20,
            right: 20
          }}>
          <img
            src={CloseModalImage}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              onClose();
            }}
            alt={product.name}
          />
        </Grid>
      </div>
    </Grid>
  );
}
