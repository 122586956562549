import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import './style.css';

import { Box, Container } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyles from '@mui/material/GlobalStyles';
import { ThemeProvider } from '@mui/material/styles';

import { StatusWalletContext } from '../../../contexts';
import theme from '../../../core/resources/theme';
import ResponsiveAppBar from '../../components/appbar';
import StatusWallet from '../../components/statuswallet';
import CardTransaction from './card';
import useUser from '../../../core/features/auth/store';

function Transactions() {
  const { setIsDashboard } = useContext(StatusWalletContext);

  const { user } = useUser();

  // const { status, connect, account } = useMetaMask();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      return navigate('/');
    }

    setIsDashboard(false);
  }, [user, navigate, setIsDashboard]);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
      <CssBaseline />

      <ResponsiveAppBar />

      <StatusWallet />

      <Container component="main" maxWidth="sm">
        <CssBaseline />

        <Box
          sx={{
            marginTop: 2,
            marginBottom: 5,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
          <CardTransaction />
        </Box>
      </Container>

      {/* Footer */}
      {/* <Footer /> */}
    </ThemeProvider>
  );
}

export default Transactions;
