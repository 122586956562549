import '../../core/resources/translate';
import '../sentry';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga4';

const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false // enable logs
};

ReactPixel.init('983444035651222', {}, options);
ReactPixel.pageView();

ReactGA.initialize(`G-08ENDN8B9X`);
