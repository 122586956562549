/* eslint-disable react/prop-types */

import "../style.css";

import { Grid } from "@mui/material";

import { useTranslation } from "react-i18next";
import { useTickets } from "../../../../core/features/lootbox/hooks/useTickets";
import EmptyTicketList from "../../../components/EmptyTicketList";
import TicketItem from "../components/TicketItem";
import { useEffect } from "react";
import { useTicketsStore } from "../../../../core/features/lootbox/store/tickets";

export default function TabTickets(props) {
  const { t } = useTranslation();
  const { isLoading, tiers } = useTickets(props.wallet);

  const { setLoading } = useTicketsStore();

  useEffect(() => {
    setLoading(isLoading);

    return () => setLoading(false);
  }, [isLoading, setLoading]);

  return (
    <>
      {tiers.map((tier) => (
        <TicketItem key={tier.id} tier={tier} />
      ))}

      {isLoading ? (
        <Grid item xs={12} lg={12} md={12}>
          <center>
            <h2 className="font-bold" style={{ marginTop: "100px" }}>
              {t("tickets.loading")}
            </h2>
          </center>
        </Grid>
      ) : null}

      {!isLoading && tiers.length === 0 && <EmptyTicketList tab="tickets" />}
    </>
  );
}
