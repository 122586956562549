/* eslint-disable react/prop-types */
import '../style.css';

import { Grid } from '@mui/material';

import SpaceshipSimpleAndroid from '../../../components/box/SpaceshipSimpleAndroid';

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import EmptyTicketList from '../../../components/EmptyTicketList';
import ModalTicketDetails from '../components-spaceship/ModalTicketDetails';
import { useSpaceshipTicketsReady } from '../../../../core/features/spaceship/hooks/useSpaceshipTicketsReady';
import { useSpaceshipShowRevel } from '../../../../core/features/spaceship/hooks/useSpaceshipShowRevel';
import { useReveal } from '../../../../core/features/nfts/hooks/useReveal';
import { hideLoading, showError, showLoading } from '../../../../infra/helpers/alerts';

export default function TabSpaceshipReady(props) {
  const { t } = useTranslation();

  const { isLoading, tiers } = useSpaceshipTicketsReady(props.wallet);
  const { mutate: handleReveal } = useReveal();
  const { handleRun } = useSpaceshipShowRevel();

  const [tier, setTier] = useState(null);

  return (
    <>
      {tier ? <ModalTicketDetails ticket={tier} onClose={() => setTier(null)} /> : null}

      {tiers.map((item) => (
        <Grid item key={item.id} xs={12} lg={2} md={3}>
          <SpaceshipSimpleAndroid
            tier={item}
            onClick={async (isRevealed) => {
              if (!item?.metadata) {
                return;
              }

              if (item.metadata && !isRevealed) {
                showLoading();

                handleReveal(
                  {
                    category: 'spaceship',
                    tokenId: item.tokenId
                  },
                  {
                    onSuccess: async () => {
                      hideLoading();
                      if (isRevealed && item.link) {
                        window.open(item.link, '_blank', 'noopener,noreferrer');
                      } else {
                        handleRun(item.tokenId);
                      }
                    },
                    onError() {
                      hideLoading();
                      showError();
                    }
                  }
                );

                return;
              }

              setTier(item);
            }}
          />
        </Grid>
      ))}

      {isLoading ? (
        <Grid item xs={12} lg={12} md={12}>
          <center>
            <h2 className="font-bold" style={{ marginTop: '100px' }}>
              {t('tickets.loading')}
            </h2>
          </center>
        </Grid>
      ) : null}

      {!isLoading && tiers.length === 0 && <EmptyTicketList tab="ready" />}
    </>
  );
}
