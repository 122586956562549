import { useTranslation } from 'react-i18next';
import { useQuote } from '../../../../../core/features/transactions/hooks/useQuote';
import { Grid, Box, Typography } from '@mui/material';
import ButtonUniversus from '../../../../../ui/components/buttonUniversus';

const AndroidBox = ({ android, onClick }) => {
  const { t } = useTranslation();
  const { data: quote } = useQuote({ productId: android.id, quantity: 1 }, true);

  return (
    <>
    {/* <ModalCrypto product={android} onClose={() => setOpen(false)} /> */}

    <Grid
      sx={{ boxShadow: 3 }}
      item
      xs={12}
      sm={6}
      p={4}
      borderRadius="10px"
      border="1px solid #1DFDFF">
      <Typography
        fontFamily="Audiowide"
        fontWeight="600"
        textAlign="center"
        fontSize="20px"
        color="#FFF">
        Android Squad
      </Typography>
      <Typography
        fontFamily="Audiowide"
        color="#1DFDDF"
        fontWeight="500"
        textAlign="center"
        fontSize="20px">
        Journey to the Unknown PACK
      </Typography>

      <Box sm={12} display="flex" justifyContent="center" alignItems="center">
        <img src="/images/card_new_pack.png" alt="" width="60%" />
      </Box>
      <Typography fontFamily="Anek Latin" fontWeight="500">
        Um pack de androids possui como chance de androids:{' '}
      </Typography>

      <Grid container xs={12}>
        <Grid item xs={4}>
          <Typography fontFamily="Anek Latin" fontWeight="bold" color="#FFFFFF">
            Common: 50%
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography fontFamily="Anek Latin" fontWeight="bold" color="#62D5D4">
            Exotic: 10%
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography fontFamily="Anek Latin" fontWeight="bold" color="#3E3E90">
            Epic: 0%
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography fontFamily="Anek Latin" fontWeight="bold" color="#6fdb85">
            Uncommon: 40%
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography fontFamily="Anek Latin" fontWeight="bold" color="#d0b73e">
            Rare: 0%
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography fontFamily="Anek Latin" fontWeight="bold" color="#d27b45">
            Mythic: 0%
          </Typography>
        </Grid>
      </Grid>

      <Box>
        <Typography className="priceEther">
          {t('share.brl')} {quote?.price > 0 ? quote.price.toFixed(2) : '--'}
        </Typography>
      </Box>

      <Grid container sx={12} spacing={1}>
        <Grid item sx={12} sm={12}>
          <ButtonUniversus colorType="green" onClick={onClick}>
            <Typography fontWeight="bold" fontSize="14px" fontFamily="Anek Latin">
              Comprar Android
            </Typography>
          </ButtonUniversus>
        
        </Grid>
        {/* <Grid item sx={12} sm={6}>
          <ButtonUniversus colorType="green">
            <Typography fontWeight="bold" fontSize="14px" fontFamily="Anek Latin">
              {t('tickets.modal.buy.buttonCripto')}
            </Typography>
          </ButtonUniversus>
        </Grid> */}
      </Grid>
    </Grid>
    </>
  );
};
export { AndroidBox };
