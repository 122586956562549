import { FormControl, InputLabel, NativeSelect } from '@mui/material';
import { inputStyle } from '../style';
import { v4 as uuidv4 } from 'uuid';
import countryList from '../countries';
import { t } from 'i18next';

export const Select = ({ country, change }) => (
  <FormControl
    style={{
      ...inputStyle,
      mb: '16px',
      width: '100%',
      borderRadius: '8px 20px 8px 20px',
      height: '56px',
      paddingLeft: '16px',
      paddingRight: '8px'
    }}
    className="select-account">
    <InputLabel
      variant="standard"
      htmlFor="uncontrolled-native"
      style={{ marginLeft: '16px', marginTop: '-6px' }}>
      {t('account.label_country')}
    </InputLabel>
    <NativeSelect
      inputProps={{
        name: 'country',
        id: 'uncontrolled-native'
      }}
      onChange={change}
      value={country}>
      {countryList.map((country) => (
        <option value={country.name} key={uuidv4()}>
          {country.name}
        </option>
      ))}
    </NativeSelect>
  </FormControl>
);
