import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { IS_DEV, SENTRY_DSN } from "../config";

if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [new BrowserTracing()],
    environment: IS_DEV ? "development" : "production",
    tracesSampleRate: 1.0,
  });
}

export default Sentry;
