import { Navigate, Outlet } from 'react-router-dom';

import useUser from '../../../core/features/auth/store';

function RouteOpen() {
  const { user } = useUser();

  if (user) {
    return <Navigate to={'/'} />;
  }

  return <Outlet />;
}

export default RouteOpen;
