import { Navigate, Route, Routes } from 'react-router-dom';
import Account from '../pages/account';

import Dashboard from '../pages/dashboard';
import Tickets from '../pages/tickets';
import Login from '../pages/login';
import Forgot from '../pages/forgot';
import Reset from '../pages/reset';
import Logout from '../pages/logout';
import AccountChangePassword from '../pages/account-change-password';

import Transactions from '../pages/transactions';
import VerifyWallet from '../pages/verifywallet';
import AppMobile from '../components/appMobile/AppMobile';
import RouteAuth from '../components/route-auth';
import RouteOpen from '../components/route-open';

function Router() {
  console.log('rerender');
  return (
    <Routes>
      <Route path="/" element={<RouteOpen />}>
        <Route path="/vocesabia" element={<Navigate to="/" />} />

        <Route path="/auth/login" element={<Login />} />
        <Route path="/auth/forgot" element={<Forgot />} />
        <Route path="/auth/reset/:email" element={<Reset />} />
      </Route>
      {/*  */}
      <Route path="/" element={<RouteAuth />}>
        <Route index element={<Dashboard />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/my-account" element={<Account />} />
        <Route path="/change-password" element={<AccountChangePassword />} />
        <Route path="/tickets" element={<Tickets />} />
        <Route path="/transactions" element={<Transactions />} />
        <Route path="/mobile-browser" element={<AppMobile />} />
      </Route>

      {/* {isMyAccount && !isDashboard ? (
          <>
            <Route exact path="/my-account" element={<Account />} />
          </>
        ) : (
          ''
        )} */}

      <Route path="/verify-wallet" element={<VerifyWallet />} />

      <Route path="/" element={<RouteOpen />}>
        <Route path="/:promoCode" element={<Login />} />
      </Route>
      {/* <Route path="*" element={<Navigate to="/" />} /> */}
    </Routes>
  );
}

export default Router;
