/* eslint-disable react/prop-types */
import "../style.css";

import { Grid } from "@mui/material";

import SpaceshipSimpleAndroid from "../../../components/box/SpaceshipSimpleAndroid";

import { useTranslation } from "react-i18next";
import { useOpenTicket } from "../../../../core/features/lootbox/hooks/useOpenTicket";
import { useTicketsPrepareds } from "../../../../core/features/lootbox/hooks/useTicketsPrepareds";
import EmptyTicketList from "../../../components/EmptyTicketList";
import { useTicketsStore } from "../../../../core/features/lootbox/store/tickets";
import { useEffect } from "react";

export default function TabPrepared(props) {
  const { t } = useTranslation();
  const { items, isLoading } = useTicketsPrepareds(props.wallet);
  const { handleOpen, isLoading: isLoadingReady } = useOpenTicket(props.wallet);

  const { setLoading } = useTicketsStore();

  useEffect(() => {
    setLoading(isLoading || isLoadingReady);

    return () => setLoading(false);
  }, [isLoading, isLoadingReady, setLoading]);

  return (
    <>
      {items.map((tier, key) => (
        <Grid item key={tier.requestId} xs={12} lg={2} md={3}>
          <SpaceshipSimpleAndroid
            tier={tier}
            isLoading={isLoadingReady}
            onClick={() => handleOpen(tier)}
          />
        </Grid>
      ))}

      {isLoading ? (
        <Grid item xs={12} lg={12} md={12}>
          <center>
            <h2 className="font-bold" style={{ marginTop: "100px" }}>
              {t("tickets.loading")}
            </h2>
          </center>
        </Grid>
      ) : null}

      {!isLoading && items.length === 0 && <EmptyTicketList tab="prepared" />}
    </>
  );
}
