import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// eslint-disable-next-line react/prop-types
function EmptyTicketList({ tab }) {
  const { t } = useTranslation();
  const [message, setMessage] = useState("");
  useEffect(() => {
    if (tab === "prepared") {
      setMessage(t("tickets.preparedEmpty"));
    } else if (tab === "ready") {
      setMessage(t("tickets.readyEmpty"));
    } else if (tab === "tickets") {
      setMessage(t("tickets.ticketEmpty"));
    }
  }, [t, tab]);
  return (
    <Grid
      item
      xs={12}
      lg={12}
      md={12}
      style={{
        marginTop: "100px",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <h2 className="font-bold" style={{}}>
        {message}
      </h2>
    </Grid>
  );
}

export default EmptyTicketList;
