/* eslint-disable no-restricted-globals */
import { useEffect } from 'react';
import { Auth } from '@aws-amplify/auth';
import { hideLoading } from '../../../infra/helpers/alerts';
import { useNavigate } from 'react-router-dom';

function Logout() {
  const navigate = useNavigate();

  console.log('vai deslogar');

  useEffect(() => {
    (async () => {
      try {
        hideLoading();
        await Auth.signOut();

        navigate('/auth/login');
      } catch (err) {
        console.log('err', err)
      } finally {
        window.location.reload();
      }
    })();
  }, [navigate]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
}

export default Logout;
