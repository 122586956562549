import { TextField } from '@mui/material';
import { inputStyle } from '../style';
import { t } from 'i18next';

export const Phone = ({ phone, change }) => (
  <TextField
    margin="normal"
    fullWidth
    required
    name="phone"
    label={t('account.cell')}
    type="tel"
    id="phone"
    autoComplete="phone"
    className="inputForm input-account"
    value={phone ?? ''}
    onChange={change}
    style={inputStyle}
  />
);
