import { useQuery } from 'react-query';
import { http } from '../../../../infra/http';
import { NftCheckIsRevealedRequest } from '../types';

export function useIsRevealed(enabled: boolean, body: NftCheckIsRevealedRequest) {
  return useQuery(
    `/v1/nft/check-is-revealed-${body.category}-${body.tokenId}`,
    async () => {
      const { data } = await http.post('/v1/nft/check-is-revealed', body);

      return data as boolean;
    },
    {
      refetchOnWindowFocus: false,
      refetchInterval: (data) => (data ? false : 6000),
      enabled: enabled
    }
  );
}
