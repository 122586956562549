function Skill({ title, value }) {

  return (
    <div className="dialog-modal-android__skill item--property">
      <div className="dialog-modal-android__skill__type font-bold">{title}</div>
      <div
        className={`dialog-modal-android__skill__value rarities-${value?.toLowerCase()}`}
      >
        {value}
      </div>
    </div>
  );
}

export default Skill;
