import { useQuery } from 'react-query';
import { http } from '../../../../infra/http';
import { GetProductsResponse } from '../types';

export function useProducts() {
  return useQuery('products', async () => {
    const { data } = await http.get<GetProductsResponse>('/v1/products');

    return data.products;
  }, {
    refetchOnWindowFocus: false,
    
  });
}
