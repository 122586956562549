/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback } from 'react';

import { IS_DEV } from '../../../../infra/config';
import { showError, showSuccess } from '../../../../infra/helpers/alerts';
import { TicketModel } from '../models/ticket.model';
import { useTicketsStore } from '../store/tickets';
import Sentry from './../../../../infra/sentry/index';

export function usePrepareTicket(ticket: TicketModel, wallet: string) {
 
  const { updateItems, setLoading, isLoading } = useTicketsStore();

  console.log('isLoading', isLoading);

  const handlePrepare = useCallback(async () => {
    try {
      console.log('prepare');

      setLoading(true);

      await ticket.prepare();

      setTimeout(() => {
        updateItems((prev) => {
          const news = prev.filter(({ tokenId }) => tokenId !== ticket.tokenId);

          console.log('ticket tokenId', ticket.tokenId);
          console.log('news', news);

          return news;
        });
      }, 300);

      showSuccess();
    } catch (err: any) {
      Sentry.captureException(err);
      Sentry.setContext('ticket', { ticket, wallet, action: 'prepare' });

      if (err.message?.includes('estimate gas')) {
        showError(
          'Não foi possível estimar o gas necessário para executar a transação. Por favor tente novamente em alguns segundos.'
        );
      } else {
        if (IS_DEV) {
          showError(err.message);
        } else {
          showError();
        }
      }
    } finally {
      setLoading(false);
    }
  }, [ticket, wallet, updateItems]);

  return {
    handlePrepare,
    isLoading
  };
}
