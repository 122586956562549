import axios from 'axios';
import { STORE_API } from '../config';
import { Auth } from '@aws-amplify/auth';

export const http = axios.create({
  baseURL: STORE_API
});

http.interceptors.request.use(async (config) => {
  const session = await Auth.currentSession();

  if (!session) {
    return config;
  }

  if (!session.isValid()) {
    return config;
  }

  const token = session.getIdToken();

  config.headers = config.headers ?? {};

  config.headers.Authorization = `Bearer ${token.getJwtToken()}`;

  return config;
});
