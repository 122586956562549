import { showError, showLoading, showSuccess } from '../../../../infra/helpers/alerts';

import { useNavigate } from 'react-router-dom';
import Sentry from '../../../../infra/sentry';
import { Auth } from '@aws-amplify/auth';

export function useForgot() {
  const navigate = useNavigate();

  const handleForgotPassword = async (email: string, message?: any) => {
    showLoading();

    try {
      await Auth.forgotPassword(email.toLowerCase());

      await showSuccess(message ?? 'Enviamos um email para recuperar sua senha!');

      navigate('/auth/reset/' + email);
    } catch (err: any) {
      Sentry.captureException(err);
      showError(err.message);
    }
  };

  return { handleForgotPassword };
}
