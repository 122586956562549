import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ButtonUniversus from "../../../ui/components/buttonUniversus/index";

const AppMobile = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div
      style={{
        height: "90vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Typography
        className="font-bold"
        component="p"
        style={{ fontSize: "15px !important", textAlign: "center" }}
      >
        {t("feedbacks.mobile_required")}
      </Typography>
      <br />
      <br />
      <ButtonUniversus onClick={() => navigate("/")}>
        {t("feedbacks.mobile_link")}
      </ButtonUniversus>
    </div>
  );
};

export default AppMobile;
