import { Navigate, Outlet, useLocation } from 'react-router-dom';

import useUser from '../../../core/features/auth/store';

function RouteAuth() {
  const { user } = useUser();

  const location = useLocation();

  if (!user) {
    return <Navigate to={'auth/login'} />;
  }

  if (
    !user.personalInfo?.document &&
    !location.pathname.includes('my-account') &&
    !location.pathname.includes('logout')
  ) {
    return <Navigate to={'my-account'} />;
  }

  return <Outlet />;
}

export default RouteAuth;
