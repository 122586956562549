import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';
import { Auth } from '@aws-amplify/auth';

export async function trackEvent(eventName: string, data: any) {
  try {
    const user = await Auth.currentAuthenticatedUser();

    console.log('user trackEvent -> ', user);

    if (user) {
      data.email = user.username;

      ReactGA.set({
        email: user.username
      });
    }

    ReactPixel.track(eventName, data);
    ReactGA.event(eventName, data);

    console.log('event', eventName, data);
  } catch (eerr) {
    console.log('deu erro no track de evento');
  }
}
