/* eslint-disable react/prop-types */
import "../style.css";

import { Grid } from "@mui/material";

import SpaceshipSimpleAndroid from "../../../components/box/SpaceshipSimpleAndroid";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTicketsReady } from "../../../../core/features/lootbox/hooks/useTicketsReady";
import EmptyTicketList from "../../../components/EmptyTicketList";
import ModalTicketDetails from "../components/ModalTicketDetails";
import { useTicketsStore } from "../../../../core/features/lootbox/store/tickets";

export default function TabReady(props) {
  const { t } = useTranslation();

  const { isLoading, tiers } = useTicketsReady(props.wallet);

  const [tier, setTier] = useState(null);

  const { setLoading } = useTicketsStore();

  useEffect(() => {
    setLoading(isLoading);

    return () => setLoading(false);
  }, [isLoading, setLoading]);

  return (
    <>
      {tier ? (
        <ModalTicketDetails ticket={tier} onClose={() => setTier(null)} />
      ) : null}

      {tiers.map((item) => (
        <Grid item key={item.id} xs={12} lg={2} md={3}>
          <SpaceshipSimpleAndroid
            tier={item}
            disabled={!item.link}
            onClick={() => {
              setTier(item);
            }}
          />
        </Grid>
      ))}

      {isLoading ? (
        <Grid item xs={12} lg={12} md={12}>
          <center>
            <h2 className="font-bold" style={{ marginTop: "100px" }}>
              {t("tickets.loading")}
            </h2>
          </center>
        </Grid>
      ) : null}

      {!isLoading && tiers.length === 0 && <EmptyTicketList tab="ready" />}
    </>
  );
}
