/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-restricted-globals */
import { Box, Button, ButtonGroup, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import ArrowLeftImage from '../../../assets/images/arrow-left.webp';
// import BannerSite from "../../../assets/images/Banner_site.png";
// import BannerMobile from "../../../assets/images/Banner_site_mobile.png";
import { isNil } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { StatusWalletContext } from '../../../contexts';
import { buttonStyled } from './style';
import useUser from '../../../core/features/auth/store';

function StatusWallet() {

  const { t } = useTranslation();

  const [dataWallet, setDataWallet] = useState(null);

  const { user } = useUser();

  const { wallet, isDashboard } = useContext(StatusWalletContext);
  const navigate = useNavigate();

  useEffect(() => {
    const loadUserInfo = () => {
      if (dataWallet != null) return;

      setDataWallet(!isNil(user?.wallet) ? user : !isNil(wallet) ? { wallet: wallet } : user);
    };

    loadUserInfo();
  }, [dataWallet, user, wallet]);

  const statusCodeWallet = ({ wallet }) => {
    if (!wallet) return 1;
    if (wallet) return 3;

    return 1;
  };
  const statusWalletBGColor = () => {
    if (dataWallet) {
      switch (statusCodeWallet(dataWallet)) {
        case 2:
          return '#ffff00';
        case 3:
          return '#3CF5848D';
        default:
          return '#ff0000';
      }
    }
    return '#ff0000';
  };

  const statusWalletBDColor = () => {
    if (dataWallet) {
      switch (statusCodeWallet(dataWallet)) {
        case 2:
          return '#ffff4d';
        case 3:
          return '#3CF5848D';
        default:
          return '#ff4d4d';
      }
    }
    return '#ff4d4d';
  };

  const statusWallet = () => {
    if (dataWallet) {
      switch (statusCodeWallet(dataWallet)) {
        case 2:
          return t('feedbacks.in_validation');
        case 3:
          return t('account.connected');
        default:
          return t('share.not_informed');
      }
    }
    return t('share.not_informed');
  };

  const statusWalletDescription = () => {
    if (dataWallet) {
      switch (statusCodeWallet(dataWallet)) {
        case 2:
          return (
            <>
              {t('feedbacks.verify_email')}
              <p
                onClick={() => navigate('/my-account')}
                style={{
                  color: '#3cf584',
                  marginLeft: '5px',
                  textDecoration: 'underline',
                  cursor: 'pointer'
                }}>
                {t('share.click_here')}
              </p>
            </>
          );
        case 3:
          return t('share.wallet_confirm');
        default:
          return (
            <>
              {t('feedbacks.wallet_required')}
              <p
                onClick={() => navigate('/my-account')}
                style={{
                  color: '#3cf584',
                  marginLeft: '5px',
                  textDecoration: 'underline',
                  cursor: 'pointer'
                }}>
                {t('share.click_here')}
              </p>
            </>
          );
      }
    }
    return (
      <>
        {t('feedbacks.wallet_required')}
        <p
          onClick={() => navigate('/my-account')}
          style={{
            color: '#3cf584',
            marginLeft: '5px',
            textDecoration: 'underline',
            cursor: 'pointer'
          }}>
          {t('share.click_here')}
        </p>
      </>
    );
  };
  return (
    <Grid container className="status-wallet">
      <Grid item md={5} xs={12}>
        {isDashboard === false ? (
          <Button
            onClick={() => navigate('/')}
            sx={buttonStyled('rgba(27, 253, 255, 0.29)', '16px', '10px 10px 10px 10px', '#2CFFF7')}
            style={{
              margin: '20px'
            }}>
            <img
              src={ArrowLeftImage}
              srcSet={ArrowLeftImage}
              alt="Universus icon"
              style={{
                width: 'auto'
              }}
            />
          </Button>
        ) : (
          <center>
            <a href="https://universus.gg/quickguide" target="__blank">
              <img
                src={t('dashboard.banner.site')}
                srcSet={t('dashboard.banner.site')}
                alt="Universus icon"
                style={{
                  width: '95%',
                  height: '15vh',
                  marginTop: '10px'
                }}
                className="banner-desktop"
              />
              <img
                src={t('dashboard.banner.mobile')}
                srcSet={t('dashboard.banner.mobile')}
                alt="Universus icon"
                style={{
                  display: 'none',
                  width: '100%',
                  marginTop: '-20px'
                }}
                className="banner-mobile"
              />
            </a>
          </center>
        )}
      </Grid>
      <Grid item md={5} xs={12}>
        <ButtonGroup style={{marginTop: "20px"}} variant="outlined" size='large' color="primary">
          <Button onClick={() => navigate("/tickets")}>Reveal</Button>
          <Button onClick={() => navigate("/")}>Store</Button>
        </ButtonGroup>
      </Grid>
      <Grid
        item
        md={2}
        xs={12}
        style={{
          display: 'flex',
          justifyContent: 'flex-center',
          alignItems: 'center',
          flexDirection: 'column',
          marginBottom: '35px'
        }}>
        {dataWallet && (
          <>
            <Typography
              className="font-bold"
              component="h3"
              sx={{
                mt: '10px',
                font: 'normal normal bold 25px Anek Latin !important'
              }}>
              Status Wallet
            </Typography>
            <Box
              variant="contained"
              sx={{
                ...buttonStyled(
                  statusWalletBGColor(),
                  '16px',
                  '18px 20px 18px 20px',
                  statusWalletBDColor()
                ),
                mt: '10px',
                color: statusCodeWallet(dataWallet) !== 2 ? '#FFFFFF' : '#000000',
                height: '57px'
              }}
              className={'btn-with-border-universus-wallet '}>
              {statusWallet()}
            </Box>
            <Typography
              component="div"
              sx={{
                mt: '10px',
                font: 'normal normal normal 14px Anek Latin !important',
                textAlign: 'center'
              }}>
              {statusWalletDescription()}
            </Typography>
          </>
        )}
      </Grid>
    </Grid>
  );
}

StatusWallet.propTypes = {
  dash: PropTypes.bool
};

export default StatusWallet;
