import { Button, CssBaseline, FormGroup, Grid, TextField } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { inputStyle } from '../../components/statuswallet/style';
import Sentry from '../../../infra/sentry';
import { showError, showLoading, showSuccess } from '../../../infra/helpers/alerts';
import theme from '../../../core/resources/theme';
import Box from '@mui/material/Box';
import { ThemeProvider } from '@mui/material/styles';
import Container from '@mui/material/Container';

import SubHeaderImage from '../../../assets/images/subheader.webp';
import WhatsappImage from '../../../assets/images/whatsapp.webp';
import { Auth } from '@aws-amplify/auth';

import Logo from '../../components/logo';

import { useNavigate, useParams } from 'react-router-dom';
import TabPanel from '../login/tab-panel';

import './Reset.css';

function Reset() {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const params = useParams<{ email: string }>();

  const [code, setCode] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleResetPassword = async () => {
    try {
      if (confirmPassword !== password) {
        return showError('As senhas devem ser iguais!');
      }

      showLoading();

      const result = await Auth.forgotPasswordSubmit(params.email!?.toLowerCase(), code, password);
      console.log('reset', result);

      // const result = await resetPassword(params.email!, code, password);

      if (!result) {
        throw new Error('Não foi possível alterar a senha!');
      }

      await showSuccess('Senha alterada com sucesso!');

      navigate('/');
    } catch (err: any) {
      console.log();
      Sentry.captureException(err);
      showError(err.message);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container
        component="main"
        maxWidth="xl"
        className="container-login"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          height: '100vh'
        }}>
        <CssBaseline />

        <Grid
          container
          spacing={2}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '90%'
          }}>
          <Grid md={4} xs={12} item sx={{ display: 'flex', marginTop: '0px' }}>
            <Box sx={{ width: '100%' }}>
              <Box
                marginTop="20px"
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column'
                }}>
                <Logo marginBottom="10px" />
                <img
                  src={SubHeaderImage}
                  srcSet={SubHeaderImage}
                  alt="Universus icon"
                  style={{ width: '25%' }}
                />
              </Box>

              <TabPanel value={0} index={0}>
                <Box
                  sx={{
                    mt: 1,
                    height: '100%',
                    width: '100%',
                    padding: '0px !important'
                  }}>
                  <FormGroup>
                    <TextField
                      margin="normal"
                      required
                      type="text"
                      autoComplete="new-password"
                      fullWidth
                      id="code"
                      placeholder="Código"
                      name="code"
                      autoFocus
                      className="inputForm inputForm-login"
                      onChange={(e) => setCode(e.target.value)}
                      sx={inputStyle}
                    />

                    <TextField
                      margin="normal"
                      required
                      type="password"
                      fullWidth
                      id="password"
                      placeholder="Nova Senha"
                      autoComplete="new-password"
                      name="password"
                      className="inputForm inputForm-login"
                      onChange={(e) => setPassword(e.target.value)}
                      sx={inputStyle}
                    />

                    <TextField
                      margin="normal"
                      required
                      type="password"
                      fullWidth
                      id="confirmPassword"
                      placeholder="Senha de confirmação"
                      autoComplete="new-password"
                      name="confirmPassword"
                      className="inputForm inputForm-login"
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      sx={inputStyle}
                    />

                    <br />
                    <br />

                    <Button
                      variant="contained"
                      onClick={() => handleResetPassword()}
                      style={{
                        background: 'rgba(44, 255, 246, 0.57)',
                        border: '2px solid #1DFDFF',
                        borderRadius: '8px',
                        borderBottomLeftRadius: '20px',
                        borderTopRightRadius: '20px',
                        paddingTop: '20px',
                        paddingBottom: '20px',
                        font: 'normal normal bold 23px/14px Anek Latin',
                        color: '#FFFFFF'
                      }}
                      className="btn-login-default">
                      {t('auth.password_recover')}
                    </Button>
                  </FormGroup>
                </Box>
              </TabPanel>
            </Box>
          </Grid>

          <Grid md={8} xs={12} item className="ninjas-background-div">
            <img
              src={'/images/pt-br/androids-crop.webp'}
              srcSet={'/images/pt-br/androids-crop.webp'}
              alt="Universus icon"
              style={{ width: 'auto', height: '100%', display: 'none' }}
              className="ninjas-background"
            />
          </Grid>
        </Grid>
      </Container>

      <a
        href="https://api.whatsapp.com/message/V62YAKBRVJXBH1?autoload=1&app_absent=0"
        target="_blank"
        rel="noopener noreferrer">
        <img
          src={WhatsappImage}
          srcSet={WhatsappImage}
          alt="Universus icon"
          style={{ width: 'auto', height: '100px' }}
          className="whatsapp-icon"
        />
      </a>
    </ThemeProvider>
  );
}

export default Reset;
