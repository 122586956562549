/* eslint-disable */

import { MetaMaskProvider } from 'metamask-react';

import Router from './ui/router';

import './infra/boot';

import { getDefaultProvider } from 'ethers';
import { createContext, Dispatch, SetStateAction, useState } from 'react';
import { createClient, WagmiConfig } from 'wagmi';
import { AuthContextProvider, ModalContextProvider, StatusWalletProvider } from './contexts';
import Loading from './ui/components/Loading';
import { ReactCognitoAuthProvider, ReactCognitoAuthConfig } from 'react-aws-cognito-auth';
import { APP_CLIENT_ID, APP_USERPOOL_ID } from './infra/config';
import { QueryClient, QueryClientProvider } from 'react-query';
import { WebGlProvider } from './contexts/WebGLContext';
import { BrowserRouter } from 'react-router-dom';

const queryClient = new QueryClient();

export interface AppContextProps {
  loading: boolean;
  setLoadingApp: Dispatch<SetStateAction<boolean>>;
}

export const AppContext = createContext<AppContextProps>({
  loading: false,
  setLoadingApp: () => {}
});

const client = createClient({
  autoConnect: true,
  provider: getDefaultProvider()
});

ReactCognitoAuthConfig({
  region: 'us-east-1',
  userPoolId: APP_USERPOOL_ID,
  userPoolWebClientId: APP_CLIENT_ID
});

function App() {
  const [loading, setLoadingApp] = useState<boolean>(true);

  return (
    <BrowserRouter>
      <WebGlProvider>
        <QueryClientProvider client={queryClient}>
          <ReactCognitoAuthProvider>
            <WagmiConfig client={client}>
              <MetaMaskProvider>
                <AppContext.Provider value={{ loading, setLoadingApp }}>
                  <AuthContextProvider>
                    <Loading ready={!loading}>
                      <StatusWalletProvider>
                        <ModalContextProvider>
                          <div className="app">
                            <Router />
                          </div>
                        </ModalContextProvider>
                      </StatusWalletProvider>
                    </Loading>
                  </AuthContextProvider>
                </AppContext.Provider>
              </MetaMaskProvider>
            </WagmiConfig>
          </ReactCognitoAuthProvider>
        </QueryClientProvider>
      </WebGlProvider>
    </BrowserRouter>
  );
}

export default App;
