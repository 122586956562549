/* eslint-disable no-nested-ternary */
import { CardActions, CardContent, Divider, Grid, Typography } from '@mui/material';
import ClockIcon from '@mui/icons-material/AccessTime';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import LogoBlackImage from '../../../assets/images/logo-black.png';
import { getTransactionsCallable } from '../../pages/auth';
import './style.css';
import useUser from '../../../core/features/auth/store';

export default function CardTransaction() {
  const { t } = useTranslation();

  const { user } = useUser();

  const [isLoading, setLoading] = useState(true);
  const [transactions, setTransactions] = useState([]);
  const [transactionError, setTransactionError] = useState({
    enable: false,
    message: ''
  });
  const navigate = useNavigate();

  const fireBaseTimeConvert = ({ _nanoseconds = 0, _seconds = 0 }) => {
    const fireBaseTime = new Date(_seconds * 1000 + _nanoseconds / 1000000);
    const atTime = `${fireBaseTime.toLocaleDateString()} ${fireBaseTime.toLocaleTimeString()}`;
    return atTime;
  };

  const convertStatusPayment = (status) => {
    let state = status.toUpperCase();
    switch (state) {
      case 'PAYMENT_AWAITING':
        return t('payments.awaiting');
      case 'PAYMENT_IN_PROGRESS':
        return t('payments.processing');
      case 'PAID':
        return t('payments.paid');
      case 'PAGO':
        return t('payments.paid');
      case 'CANCELLED':
        return t('payments.canceled');
      case 'MINT_PROCESSING':
      case 'AUTO_MINT_PROCESSING':
        return t('payments.creating_nft');
      case 'MINTED':
        return t('payments.transferred');
      default:
        return t('payments.awaiting');
    }
  };

  const convertStatusPaymentColor = (statusPayment) => {
    switch (statusPayment) {
      case 'PAYMENT_AWAITING':
        return '#a8a805';
      case 'PAYMENT_IN_PROGRESS':
        return '#a8a805';
      case 'PAID':
        return 'green';
      case 'pago':
        return 'green';
      case 'CANCELLED':
        return 'red';
      case 'MINT_PROCESSING:':
      case 'AUTO_MINT_PROCESSING':
        return '#8A37EA';
      case 'MINTED':
        return 'green';
      default:
        return '#a8a805';
    }
  };

  useEffect(() => {
    if (user?.uid) {
      getTransactionsCallable().then((transactionns) => {
        setLoading(false);
        if (
          transactionns?.result?.code === 'ERROR_GET_TRANSACTIONS' ||
          transactionns?.data.length === 0
        ) {
          setTransactionError({
            enable: true,
            message:
              transactionns?.result?.code === 'ERROR_GET_TRANSACTIONS'
                ? t('errors.list_transactios')
                : t('errors.empty')
          });
        } else {
          setTransactions(transactionns?.data);
        }
      });
    }
  }, [navigate, t, user?.uid]);

  return (
    <>
      {transactionError.enable ? <h2 className="font-bold">{transactionError.message}</h2> : ''}
      {transactions && transactions.length > 0 && transactionError.enable === false ? (
        transactions.map((transaction) => (
          <Grid
            sx={{
              minWidth: '33vw',
              maxWidth: '33vw'
            }}
            style={{ boxShadow: '0px !important', marginBottom: '15px' }}
            className="card-content"
            key={uuidv4()}>
            <Divider />
            <br />
            <br />
            <br />
            <CardContent className="card-content-header">
              <img
                src={`data:image/png;base64,${transaction?.qrCodeBase64}`}
                className="pix-qrcode"
                alt={transaction?.paymentMethod}
              />
              <Grid
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'flex-start',
                  flexDirection: 'column',
                  width: '100%',
                  height: '100px',
                  paddingLeft: '20px'
                }}>
                <Typography
                  className="title-mode-payment font-bold"
                  sx={{
                    mb: 1.5,
                    color: 'black !important'
                  }}>
                  {t('share.payment')} {transaction.paymentMethod}
                </Typography>
                <Typography
                  className="hour-transaction"
                  variant="body2"
                  sx={{ fontWeight: 'bold', fontSize: '17px' }}>
                  <div class="createdAt">
                    <ClockIcon />
                    <span>{fireBaseTimeConvert(transaction.createdAt)}</span>
                  </div>
                </Typography>
                <Typography className="status-transaction" component="div" color="black">
                  <div class="Status">
                    <strong style={{}}>Status:</strong>
                    <Typography
                      className="status-transaction"
                      component="p"
                      color={convertStatusPaymentColor(transaction.paymentStatus)}>
                      {convertStatusPayment(transaction.status)}
                    </Typography>
                  </div>
                </Typography>
              </Grid>
            </CardContent>

            <CardActions className="card-content-body">
              <Typography
                id="transition-modal-title"
                variant="h6"
                component="h2"
                style={{ textTransform: 'capitalize' }}
                className="font-bold-card-transactions ">
                {transaction.assetName}
              </Typography>

              <br />
              {/* <Typography className="font-bold-card-transactions" color="black">
								${transaction.priceETH} WETH
							</Typography> */}
              <Typography className="font-bold-card-transactions" color="black"></Typography>
              <br />
              <Typography component="h3">
                {transaction.priceBRL} BRL <strong>(BRZ)</strong>
              </Typography>
              <br />
              <Typography component="h3">{user?.email}</Typography>
              <br />

              <Grid item className="footer-card">
                <img src={LogoBlackImage} srcSet={LogoBlackImage} alt="Universus icon" />
              </Grid>
            </CardActions>
            <br />
            <br />
          </Grid>
        ))
      ) : isLoading ? (
        <h2 className="font-bold">{t('share.loading_transactions')}...</h2>
      ) : (
        ''
      )}
    </>
  );
}
