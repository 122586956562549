import { http } from '../../../../infra/http';
import { TransferoQuoteCurrencyResponse } from '../types';
import { useEffect, useCallback, useState } from 'react';

export function useQuote(
  params: { productId: string; quantity: number },
  disabledLooping = false
) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<TransferoQuoteCurrencyResponse | undefined>();

  const quantity = !!params.quantity ? +params.quantity : 1;

  const handleLoad = useCallback(async () => {
    setIsLoading(true);

    try {
      const { data: quote } = await http.post('/v1/quote', {
        productId: params.productId,
        quantity: quantity
      });

      setData(quote);
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  }, [ quantity, params.productId]);

  useEffect(() => {
    (async () => {
      await handleLoad();
    })();
  }, [handleLoad, disabledLooping]);

  return { isLoading, data };
}
