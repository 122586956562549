import { useEffect } from "react";
import Swal from "sweetalert2";

export function showSuccess(
  message = "Operação realizada com sucesso",
  title = "Sucesso"
) {
  return new Promise((resolve) => {
    setTimeout(() => resolve(Swal.fire(title, message, "success")), 250);
  });
}

export function showError(
  message = "Houve um problema ao realizar esta operação",
  title = "Erro"
) {
  return Swal.fire(title, message, "error");
}

export function showWarning(
  message = "Houve um problema ao realizar esta operação",
  title = "Aviso"
) {
  return Swal.fire(title, message, "warning");
}
 

export function showConfirm(
  message = "Deseja realmente realizar esta operação?"
) {
  return Swal.fire({
    showCancelButton: true,
    cancelButtonText: "Cancelar",
    title: "Aviso",
    icon: "info",
    text: message,
  });
}

export function showLoading() {
  return Swal.showLoading();
}

export function hideLoading() {
  return Swal.close();
}

export function showErrorMetamaskConnection(
  message = "Houve um problema ao realizar esta operação",
  tryAgainButton,
  cancelButton,
  connect
) {

  return Swal.fire({
    title: "Erro",
    icon: "warning",
    text: message,
    showCloseButton: true,
    showCancelButton: true,
    focusConfirm: false,
    confirmButtonText: tryAgainButton,
    cancelButtonText: cancelButton,
    confirmButtonColor: "#3cf584",
    cancelButtonColor: "#ff4d4d",
  }).then(async (result) => {
    if (result.isConfirmed) {
      await connect();
    }
  });
}

export function showSuccessAccount(
  message,
  tryAgainButton,
  cancelButton,
  navigate
) {
  return Swal.fire({
    title: "",
    icon: "success",
    text: message,
    showCloseButton: true,
    showCancelButton: true,
    focusConfirm: false,
    confirmButtonText: tryAgainButton,
    cancelButtonText: cancelButton,
    confirmButtonColor: "#3cf584",
    cancelButtonColor: "#ff4d4d",
  }).then((result) => {
    if (result.isConfirmed) {
      return navigate("/");
    }
  });
}

export function useLoading(bool) {
  useEffect(() => {
    if (bool) {
      setTimeout(() => {
        showLoading();
      }, 200);
    } else {
      hideLoading();
    }
  }, [bool]);
}
