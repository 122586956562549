const raritiesColors = {
  exotic: "#1DFDFF",
  epic: "#8A37EA",
  common: "#ffffff",
  unusual: "#3CF584",
  rare: "#FFDA10",
  mythical: "#FF9133",
};

export default raritiesColors;
