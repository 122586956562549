/* eslint-disable react/no-unknown-property */
/* eslint-disable no-prototype-builtins */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable no-constant-condition */
/* eslint-disable no-restricted-globals */
import { Select } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import i18next from 'i18next';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import HeaderImage from '../../../assets/images/header.webp';
import WhatsAppImage from '../../../assets/images/whatsapp.webp';
import { ifMobile } from '../../../infra/adapters/deepLink';
import Logo from '../logo';
import './index.css';
import appbarStyle from './style';
import useUser from '../../../core/features/auth/store';

function ResponsiveAppBar() {
  const { t } = useTranslation();

  const { user } = useUser();

  const [anchorElUser, setAnchorElUser] = useState(null);
  const navigate = useNavigate();

  const settings = useMemo(
    () => [
      {
        text: t('menus.account'),
        link: '/my-account',
        enabled: user
      },
      {
        text:'Trocar senha',
        link: '/change-password',
        enabled: true
      },
      {
        text: t('menus.tickets'),
        link: ifMobile && !window.ethereum ? '/mobile-browser' : '/tickets',
        enabled: user
      },
      {
        text: t('menus.store'),
        link: '/transactions',
        enabled: false
      },
      {
        text: t('menus.logout'),
        link: '/logout',
        enabled: true
      }
    ],
    [t, user]
  );

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const getFirstLetterName = (name) =>
    name === null || name === undefined ? 'U' : name.charAt(0).toUpperCase();

  const [translate, setTranslate] = useState(i18next.language);

  const handleChangeTranslate = (v) => {
    i18next.changeLanguage(v);
    setTranslate(v);

    localStorage.setItem('translate', v);

    window.location.reload();
  };

  return (
    <AppBar position="static" elevation={0} sx={appbarStyle} xs={12}>
      <Container maxWidth="xl" sx={{ height: '100%' }}>
        <Toolbar
          sx={{
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
          disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href={location.hash.includes('dashboard') ? '' : '/'}
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none'
            }}>
            <img
              src={HeaderImage}
              webp
              srcSet={HeaderImage}
              alt="Universus icon"
              style={{ width: '80%' }}
            />
          </Typography>

          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none'
            }}>
            <Logo />
          </Typography>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: 'none', md: 'flex' },
              justifyContent: 'flex-end'
            }}></Box>

          <Box
            sx={{
              flexGrow: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%'
            }}>
            <Select
              value={translate}
              size="small"
              style={{ marginRight: 16 }}
              onChange={(v) => handleChangeTranslate(v.target.value)}>
              <MenuItem value={'en'}>English</MenuItem>
              <MenuItem value={'pt-br'}>Portuguese</MenuItem>
            </Select>
            {/* <a
              href="https://wa.me/message/V62YAKBRVJXBH1"
              target="_blank"
              rel="noopener noreferrer">
              <img
                src={WhatsAppImage}
                srcSet={WhatsAppImage}
                alt="Universus icon"
                style={{
                  width: '90px',
                  marginRight: '10px'
                }}
              />
            </a> */}

            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar
                  alt={getFirstLetterName(user?.name ?? 'Universus')}
                  src="/static/images/avatar/3.webp"
                />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}>
              {settings
                .filter((setting) => setting.enabled)
                .map((setting) => (
                  <MenuItem
                    onClick={() => {
                      navigate(setting.link);
                      handleCloseUserMenu(setting);
                    }}
                    key={uuidv4()}>
                    <Typography textAlign="center">{setting.text}</Typography>
                  </MenuItem>
                ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
