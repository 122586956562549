import { Tab } from "@mui/material";

import { Box } from "@mui/system";

export default function TabHeader({
  value,
  count,
  title,
  onClick,
  disabled
}: {
  value: string;
  title: string;
  disabled?: boolean;
  count: number;
  onClick: () => void;
}) {

  return (
    <Tab
      value={value}
      onClick={onClick}
      disabled={!!disabled}
      label={
        <Box display="flex" gap="8px" flexDirection={"row"}>
          {title}

          {count ? (
            <Box
              bgcolor="white"
              padding="4px"
              fontSize="0.8em"
              borderRadius={"4px"}
              color="#333"
            >
              {count}
            </Box>
          ) : null}
        </Box>
      }
    />
  );
}
