import { Alert, Button, FormGroup, TextField } from '@mui/material';
import { Box } from '@mui/system';

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import validator from 'validator';
import { inputStyle } from '../login/styles';
import { useRegister } from '../../../core/features/auth/hooks/useRegister';
import { showError } from '../../../infra/helpers/alerts';
import PasswordChecklist from 'react-password-checklist';
 
export default function Register() {
  const { t } = useTranslation();
  const promoCode = localStorage.getItem("promoCode")
  const { handleRegister, onChange, form } = useRegister();

  const [invalidPassword, setInvalidPassword] = useState(true);
  const [registerIsSend, registerIsSendEnable] = useState(false);

  useEffect(() => {
    onChange("promoCode", promoCode)
  }, [promoCode])

  const signInValidator = async () => {
    if (invalidPassword) {
      return showError('Senha inválida!');
    }

    registerIsSendEnable(false);
    if (
      form.password === form.confirmPassword &&
      form.password?.length > 5 &&
      validator.isEmail(form.email)
    ) {
      await handleRegister();
    } else {
      if (form.password !== form.passwordConfirm) {
        registerIsSendEnable({
          success: false,
          message: 'A senha que você preencheu não é a mesma.'
        });
      }
      if (form.password?.length <= 5) {
        registerIsSendEnable({
          success: false,
          message: 'A senha que você preencheu não tem mais que 4 digitos.'
        });
      }
      if (!validator.isEmail(form.email)) {
        registerIsSendEnable({
          success: false,
          message: 'O email que você preencheu não é a válido.'
        });
      }
      setTimeout(() => registerIsSendEnable(false), 7000);
    }
  };

  return (
    <Box
      noValidate
      sx={{
        mt: 1,
        height: '100%',
        width: '100%',
        padding: '0px !important'
      }}>
      <FormGroup>
        {registerIsSend ? (
          <Alert
            variant="outlined"
            severity={registerIsSend.success ? 'success' : 'error'}
            fontSize="17px">
            {registerIsSend.message}
          </Alert>
        ) : (
          <></>
        )}

        <TextField
          margin="normal"
          required
          fullWidth
          id="name"
          placeholder={t('account.name')}
          name="name"
          autoComplete="name"
          autoFocus
          className="inputForm inputForm-login"
          onChange={(e) => onChange('name', e.target.value)}
          sx={inputStyle}
        />

        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          placeholder={t('account.email')}
          name="email"
          autoComplete="email"
          autoFocus
          className="inputForm inputForm-login"
          onChange={(e) => onChange('email', e.target.value)}
          sx={inputStyle}
        />

        <div style={{ position: "relative", display: 'none' }}>
          <div style={{ position: "absolute", color: "#1efdff", top: "calc(50% - 13px)", left: 0, width: 120, paddingLeft: 10 }} >{t('auth.referred_by')}:</div>
          <TextField
            margin="normal"
            required
            style={{ paddingLeft: 105 }}
            fullWidth
            id="promoCode"
            name="promoCode"
            autoComplete="promoCode"
            placeholder={t('auth.promo_code')}
            className="inputForm inputForm-login"
            onChange={(e) => onChange('promoCode', e.target.value)}
            sx={inputStyle}
            defaultValue={promoCode}
          />
        </div>

        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          placeholder={t('auth.password')}
          type="password"
          id="password"
          autoComplete="current-password"
          className="inputForm inputForm-login"
          onChange={(e) => onChange('password', e.target.value)}
          sx={inputStyle}
        />

        <TextField
          margin="normal"
          required
          fullWidth
          name="password-confirm"
          placeholder={t('auth.password_confirm')}
          type="password"
          id="password-confirm"
          autoComplete="current-password"
          className="inputForm inputForm-login"
          onChange={(e) => onChange('confirmPassword', e.target.value)}
          sx={inputStyle}
        />

        <PasswordChecklist
          rules={['minLength', 'match', 'letter']}
          minLength={8}
          value={form.password || ''}
          messages={{
            capital: 'Senha precisa ter letra maiuscula',
            number: 'Senha precisa conter número',
            minLength: 'Senha precisa ter no mínino 8 caracteres',
            match: 'As senhas precisam serem iguais!',
            letter: 'Senha precisa ter letra',
            specialChar: 'Senha precisa ter algum caractere especial'
          }}
          valueAgain={form.confirmPassword || ''}
          onChange={(isValid) => {
            setInvalidPassword(!isValid);
          }}
        />

        <Button
          variant="contained"
          onClick={() => signInValidator()}
          style={{
            background: 'rgba(44, 255, 246, 0.57)',
            border: '2px solid #1DFDFF',
            borderRadius: '8px',
            borderBottomLeftRadius: '20px',
            borderTopRightRadius: '20px',
            paddingTop: '20px',
            paddingBottom: '20px',
            font: 'normal normal bold 23px/14px Anek Latin',
            color: '#FFFFFF',
            marginTop: '8px'
          }}
          disabled={registerIsSend ? registerIsSend.success : false}
          className="btn-register">
          {t('auth.register')}
        </Button>

        {/* <Button
          variant="outlined"
          className="login__btn login__google"
          onClick={() => {
            signInWithGoogle()
              .then((data) => {
                registerIsSendEnable({ success: true, message: data.message });
              })
              .catch((error) => {
                Sentry.captureException(error);
                registerIsSendEnable({
                  success: false,
                  message: error.message
                });
              });
          }}
          sx={{
            marginTop: 2,
            backgroundColor: '#ffffff',
            fontSize: '22px'
          }}
          startIcon={<GoogleIcon />}
          disabled={registerIsSend ? registerIsSend.success : false}>
          {t('auth.register_with_google')}
        </Button> */}

        <br />
      </FormGroup>
    </Box>
  );
}
