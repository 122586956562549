import axios from 'axios';
import { ethers } from 'ethers';
import { Auth } from '@aws-amplify/auth';

import { UniversusSkillTreeNFTContractAbi as AndroidABI } from '@universusgame/smart-contracts/dist/contracts/UniversusSkillTreeNFT.abi';
import { LootBoxNFT_v2ContractAbi as LootBoxABI } from '@universusgame/smart-contracts/dist/contracts/LootBoxNFT_v2.abi';
import { AndroidReveal_v2ContractAbi as RevealABI } from '@universusgame/smart-contracts/dist/contracts/AndroidReveal_v2.abi';

import {
  UniversusSkillTreeNFT,
  LootBoxNFT_v2,
  AndroidReveal_v2
} from '@universusgame/smart-contracts';

import { API_METADATA, OPENSEA_URL } from '../../../../infra/config';
import { UniversusProvider } from '../../../../infra/providers/universus-provider';

const win: any = window;

export const increaseGasLimit = (estimatedGasLimit: any) => {
  return estimatedGasLimit.mul(130).div(100); // increase by 30%
};

export function getProvider() { 
  const provider = new ethers.providers.Web3Provider(win.ethereum);

  return provider;
}


export  function getReadProvider() {
  // const session = await Auth.currentSession();

  // const token = session.getIdToken();

  // const provider = new UniversusProvider(token.getJwtToken());
  const provider = new ethers.providers.AlchemyWebSocketProvider('matic', '71gQKit2zpxHEuQkDneo6-2ZmeoLYkS0');
 
  return provider;
}

export function getLootboxContract(providerOrSigner: any, address: string) {
  const contract = new ethers.Contract(address, LootBoxABI, providerOrSigner) as LootBoxNFT_v2;

  return contract;
}

export function getRevealContract(providerOrSigner: any, address: string) {
  const contract = new ethers.Contract(address, RevealABI, providerOrSigner) as AndroidReveal_v2;

  return contract;
}

export function getAndroidContract(providerOrSigner: any, address: string) {
  const contract = new ethers.Contract(
    address,
    AndroidABI,
    providerOrSigner
  ) as UniversusSkillTreeNFT;

  return contract;
}

export async function getBalance(contract: any, wallet: string): Promise<number> {
  const _balanceOf = await contract.balanceOf(wallet);
  const _balanceValue = _balanceOf.toNumber();

  return _balanceValue;
}

export async function getAndroidMetadata(android: string, tokenId: string) {
  try {
    const url = `https://${API_METADATA}.universusgame.net/metadata/android/${tokenId}`;

    console.log('url', url);

    const { data } = await axios.get(url);

    console.log('data', data);

    const link = `${OPENSEA_URL}${android}/${tokenId}`;

    if (!data?.name) {
      return null;
    }

    return { ...data, link };
  } catch (err) {
    return null;
  }
}
