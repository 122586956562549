import { Signer, ethers } from 'ethers';
import { ShipRevealContractAbi as RevealABI } from '@universusgame/smart-contracts/dist/contracts/ShipReveal.abi';
import { ShipRevealIQContractAbi as ShipRevealIQABI } from '@universusgame/smart-contracts/dist/contracts/ShipRevealIQ.abi';
import { params } from '../../../../core/features/lootbox/data/network-params';
import { SPACESHIP_IQ_LOOTBOX, SPACESHIP_IQ_REVEAL, SPACESHIP_LOOTBOX, SPACESHIP_PROMO, SPACESHIP_REVEAL } from '../../../../infra/config';
import { LootBoxNFT_v2ContractAbi as LootBoxABI } from '@universusgame/smart-contracts/dist/contracts/LootBoxNFT_v2.abi';
import { UniversusSkillTreeNFTContractAbi as UniversusSkillTreeNFTABI } from '@universusgame/smart-contracts/dist/contracts/UniversusSkillTreeNFT.abi';

import { UniversusSkillTreeNFT, LootBoxNFT_v2, ShipReveal, ShipRevealIQ } from '@universusgame/smart-contracts';
import { http } from '../../../../infra/http';

export function getSpaceshipLootboxContract(providerOrSigner: ethers.providers.Provider | Signer) {
  const contract = new ethers.Contract(
    SPACESHIP_LOOTBOX,
    LootBoxABI,
    providerOrSigner
  ) as LootBoxNFT_v2;

  return contract;
}

export function getSpaceshipIqLootboxContract(providerOrSigner: ethers.providers.Provider | Signer) {
  const contract = new ethers.Contract(
    SPACESHIP_IQ_LOOTBOX,
    LootBoxABI,
    providerOrSigner
  ) as LootBoxNFT_v2;

  return contract;
}

export function getSpaceshipLootboxPromoContract(providerOrSigner: ethers.providers.Provider | Signer) {
  const contract = new ethers.Contract(
    SPACESHIP_PROMO,
    LootBoxABI,
    providerOrSigner
  ) as LootBoxNFT_v2;

  return contract;
}

export function getSpaceshipContract(providerOrSigner: ethers.providers.Provider | Signer) {
  const contract = new ethers.Contract(
    params.spaceship,
    UniversusSkillTreeNFTABI,
    providerOrSigner
  ) as UniversusSkillTreeNFT;

  return contract;
}

export function getSpaceshipRevealContract(providerOrSigner: any) {
  const contract = new ethers.Contract(SPACESHIP_REVEAL, RevealABI, providerOrSigner) as ShipReveal;

  return contract;
}

export function getSpaceshipIqRevealContract(providerOrSigner: any) {
  const contract = new ethers.Contract(SPACESHIP_IQ_REVEAL, ShipRevealIQABI, providerOrSigner) as ShipRevealIQ;

  return contract;
}


export async function getSpaceshipMetadata(
  tokenId: string
): Promise<VerifySpaceshipOutputSpaceshipDTO | undefined> {
  const { data }: { data: VerifySpaceshipOutputDTO } = await http.post(`/v1/verify-spaceship`, {
    tokenId
  });

  // const link = `${OPENSEA_URL}${params.spaceship}/${tokenId}`;

  return data.spaceship;
}

export interface VerifySpaceshipOutputDTO {
  status: 'not-found' | 'waiting' | 'success';
  spaceship?: VerifySpaceshipOutputSpaceshipDTO | undefined;
}

export interface VerifySpaceshipOutputSpaceshipDTO {
  tokenId: string;
  rarity: any;
  name?: string;
  imageUrl?: string;
  category: string;
  albedo1: string;
  albedo2: string;
  albedo3: string;

  albedoName1: string;
  albedoName2: string;
  albedoName3: string;

  link?: string;
  emission: {
    red: number;
    green: number;
    blue: number;
    intensity: number;
  };
}
