import { hideLoading, showError, showLoading, showSuccess } from '../../../../infra/helpers/alerts';

import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import Sentry from '../../../../infra/sentry';
import { useCallback, useState } from 'react';
import { UserRegister } from '../types';
import { STORE_API } from '../../../../infra/config';
import { Auth } from '@aws-amplify/auth';
import { trackEvent } from '../../../../infra/helpers/events';

export function useRegister() {
  const [form, setForm] = useState<UserRegister>({} as UserRegister);

  const navigate = useNavigate();

  const onChange = useCallback((prop: keyof UserRegister, value: string) => {
    setForm((prev) => ({ ...prev, [prop]: value }));
  }, []);

  const handleRegister = async () => {
    showLoading();

    try {
      await axios.post(`${STORE_API}/v1/auth/signup`, form);

      await Auth.signIn({
        username: form.email?.toLowerCase(),
        password: form.password
      });

      await trackEvent('Cadastro', { email: form.email?.toLowerCase() });

      await showSuccess();

      navigate('/');

      window.location.reload();
    } catch (err: any) {
      Sentry.captureException(err);

      setTimeout(() => {
        showError(err.response?.data?.error ?? err.message);
      }, 500);
    } finally {
      hideLoading();
    }
  };

  return { handleRegister, onChange, form };
}
