/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/prop-types */
import {
  Box, Dialog, DialogContent,
  Slide
} from "@mui/material";
import { forwardRef, useMemo, useRef } from "react";
import androidImg from "../../../../../assets/images/android-animation.gif";
import CloseModalImage from "../../../../../assets/images/close-modal.webp";
import { TicketModel } from "../../../../../core/features/lootbox/models/ticket.model";
import ButtonUniversus from "../../../../components/buttonUniversus";
import ProgressItem from "../ProgressItem";
import Skill from "../Skill";

import './styles.css'

const Transition = forwardRef(function Transition(props: any, ref) {
  return (
    <Slide children={props.children} direction="up" ref={ref} {...props} />
  );
});

interface ModalTicketDetailsProps {
  onClose: () => void;
  ticket: TicketModel;
}

function ModalTicketDetails({ onClose, ticket }: ModalTicketDetailsProps) {
  const ref = useRef<HTMLImageElement>(null);

  const { metadata } = ticket;

  const skills = useMemo(() => ticket.primarySkills.reduce((prevValue: Array<any>, currentValue: any): any => {
    if (currentValue.trait_type === "Rarity") {
      prevValue = [currentValue, ...prevValue]
    } else {
      prevValue.push(currentValue)
    }

    return prevValue;
  }, []), [ticket.primarySkills]);

  const skills2 = ticket.secondaySkills;

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={true}
      onClose={onClose}
      TransitionComponent={Transition}
      keepMounted
      className="dialog-modal-android"
    >

      <DialogContent className="dialog-modal-android__content">

        <Box
          style={{
          }}
          className="buttonMobile"
        >
          <img
            src={CloseModalImage}
            style={{ cursor: "pointer", height: "44px" }}
            onClick={onClose}
          /></Box>

        <Box
          style={{ display: "flex" }}
          flexDirection={{ xs: "column", md: "row" }}
        >
          <Box
            display={"flex"}
            flexDirection="column"
            justifyContent="center"
            alignItems={"center"}
          >
            <img
              ref={ref}
              src={ticket.imageUrl!}
              onError={() => {
                ref.current?.setAttribute("src", androidImg);
              }}
              className="dialog-modal-android__image"
            />

            {!!ticket.link ? (
              <ButtonUniversus
                colorType={"green"}
                onClick={() => window.open(ticket.link!, "_blank")}
              >
                <>Ver no opensea</>
              </ButtonUniversus>
            ) : null}
          </Box>
          <Box style={{ flex: 1 }}>
            <Box
              style={{
                display: "flex",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "90%"
                }}
              >
                <h2 className="font-bold">{ticket.name}</h2><br />
                <span>{metadata?.description}</span>
              </Box>

              <Box
                className="buttonDesktop"
              >
                <img
                  src={CloseModalImage}
                  style={{ cursor: "pointer", height: "44px" }}
                  onClick={onClose}
                /></Box>


            </Box>

            <Box
              style={{
                display: "flex",
                marginTop: 32,
                flex: 1,
                gap: 8,
                flexWrap: "wrap",
              }}
              className="modal-android-skills"
            >
              {skills.map((skill, index) => (
                <Skill
                  key={index}
                  title={skill.trait_type}
                  value={skill.value}
                />
              ))}
            </Box>

            <Box
              style={{
                marginTop: 32,
                gap: 16,
                display: "flex",
                flexDirection: "column",
              }}
            >
              {skills2.map((skill, index) => (
                <ProgressItem
                  key={index}
                  title={skill.trait_type}
                  percent={+skill.value}
                />
              ))}
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default ModalTicketDetails;
