import { TextField } from '@mui/material';
import { inputStyle } from '../style';
import { t } from 'i18next';

export const Name = ({ name, change }) => (
  <TextField
    margin="normal"
    fullWidth
    id="name"
    label={t('account.name')}
    name="name"
    required
    autoFocus
    autoComplete="text"
    className="inputForm input-account inputstylezed"
    value={name ?? ''}
    onChange={change}
    style={inputStyle}
  />
);
