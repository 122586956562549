import { useMetaMask } from "metamask-react";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { StatusWalletContext } from "../../../../contexts";
import { ifMobile } from "../../../../infra/adapters/deepLink";
import { showError, showErrorMetamaskConnection } from "../../../../infra/helpers/alerts";
import { useTicketsStore } from "../store/tickets";
import Sentry from './../../../../infra/sentry/index';

const _win: any = window;

export function useTicketPage() {
  const { connect, status: statusMetamask, account: wallet } = useMetaMask();

  const { t } = useTranslation();

  const { items } = useTicketsStore();
  const navigate = useNavigate();

  const { setIsDashboard } = useContext<any>(StatusWalletContext);

  const [plot, setPlot] = useState<number>(0);
  useEffect(() => {
    setIsDashboard(false);

    if (wallet) {
      return;
    }

    (async () => {
      try {
        const [account = false] = await _win.ethereum.request({
          method: "eth_accounts",
        });

        if (account || statusMetamask === "connected") {
          const address = _win.ethereum.selectedAddress;

          if (address.toLowerCase() !== account.toLowerCase()) {
            await showError(t("feedbacks.wallet_register_required"));
            await connect();
          }
        } else {
          if (plot === 0) {
            await showErrorMetamaskConnection(
              t("metamask.disconnected"),
              t("metamask.buttons.tryAgain"),
              t("metamask.buttons.close"),
              connect
            )
            setPlot(1)
          }
        }
        //  await connect();
      } catch (err) {
        Sentry.captureException(err);
        navigate("/");
      }
    })();
  }, [connect, navigate, setIsDashboard, statusMetamask, t, wallet, plot, setPlot]);

  useEffect(() => {
    if (ifMobile && !window.ethereum) {
      navigate("/mobile-browser");
    }
  }, [navigate]);

  return { items };
}
