const cardContentStyle = {
  display: "flex",
  justifyContent: "flex-end",
  flexDirection: "column",
  alignItems: "center",
  padding: 0,
  marginTop: "40px",
};

export { cardContentStyle };
