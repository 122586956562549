/* eslint-disable react-hooks/exhaustive-deps */
import RefreshIcon from '@mui/icons-material/Refresh';
import { Tabs } from '@mui/material';

import { Box } from '@mui/system';
import { useTicketsStore } from '../../../../../core/features/lootbox/store/tickets';
import { useTranslation } from 'react-i18next';
import { useTicketPage } from '../../../../../core/features/lootbox/hooks/useTicketPage';
import ButtonUniversus from '../../../../components/buttonUniversus';
import TabHeader from './TabHeader';

export default function TabsTickets({
  value,
  onChange
}: {
  value: string;
  onChange: (v: string) => void;
}) {
  const { t } = useTranslation();

  const { items: tiers } = useTicketPage();

  const { refresh, isLoading } = useTicketsStore();

  const countPending = tiers.filter((item) => item.status === 'pending');
  const countPrepared = tiers.filter((item) => item.status === 'prepared');
  const countOpened = tiers.filter((item) => item.status === 'opened');

  const tabs = [
    {
      type: 'pending',
      title: t('share.tab_android_tickets'),
      count: countPending.length
    },
    {
      type: 'prepared',
      title: t('share.tab_prepared'),
      count: countPrepared.length
    },
    { type: 'opened', title: t('share.tab_opened'), count: countOpened.length }
  ];

  return (
    <Box
      sx={{ borderBottom: 1, borderColor: 'divider' }}
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent={'space-between'}>
      <Tabs value={value}>
        {tabs.map((item, index) => (
          <TabHeader
            disabled={isLoading}
            key={index}
            value={item.type}
            count={item.count}
            title={item.title}
            onClick={() => onChange(item.type)}
          />
        ))}
      </Tabs>

      <ButtonUniversus
        disabled={isLoading}
        onClick={() => {
          console.log('clicou reload');
          refresh();
        }}>
        <RefreshIcon />
      </ButtonUniversus>
    </Box>
  );
}
