import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

import { useContext } from "react";
import { ModalContext } from "../../../contexts/ModalContext";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50vw",
  height: "fit-content",
  boxShadow: 0,
  p: 4,
  font: "normal normal bold 21px Anek Latin !important",
  color: "#FFFFFF !important",
  background: "#05213C !important",
  borderRadius: "8px",
  borderTopLeftRadius: "30px !important",
  borderBottomRightRadius: "30px !important",
  border: "2px solid #1DFDFF",
  padding: "0px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
};

export default function ModalMessage() {
  const { enabled, toggleModalEnabled, tier } = useContext(ModalContext);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={enabled}
        onClose={() => {
          toggleModalEnabled();
        }}
        closeAfterTransition
      >
        <Box item sx={style} className="modalItem" xs={12} md={7}>
          <h1>{tier?.name}</h1>
          <p style={{ textAlign: "center" }}>{tier?.message}</p>
        </Box>
      </Modal>
    </div>
  );
}
