/* eslint-disable react/prop-types */
import { Box } from "@mui/material";

import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";

const Progress = ({ value }) => (
  <div className="dialog-modal-android__progress__NumericTrait--bar">
    <div
      className="dialog-modal-android__progress__NumericTrait--bar-fill"
      style={{ width: value + "%" }}
    ></div>
  </div>
);

function LinearProgressWithLabel({ percent, title, key }) {

  return (
    <Box
      sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}
      key={key}
    >
      <Box sx={{ width: "100%", display: "flex", height: "80%" }}>
        <Typography
          variant="body2"
          color="#FFFFFF !important"
          style={{
            textTransform: "capitalize",
            width: "90%",
            fontFamily: "Poppins, sans-serif",
            lineHeight: "1.5",
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="body2"
          color="#FFFFFF !important"
          style={{ width: "10%", display: "flex", justifyContent: "flex-end" }}
        >{`${percent}/10`}</Typography>
      </Box>
      <Box sx={{ width: "100%" }}>
        {/* <Progress value={props.value} /> */}
        <Progress value={percent * 10} />
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  percent: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  key: PropTypes.number.isRequired,
};

// export function LinearWithValueLabel(props) {
//   return (
//     <Box sx={{ width: "100%" }}>
//       <LinearProgressWithLabel value={props.percent * 10} />
//     </Box>
//   );
// }

export default LinearProgressWithLabel;
