import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import {
  FIREBASE_API_KEY,
  FIREBASE_APP_ID,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET,
} from "../../../infra/config";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: FIREBASE_API_KEY,
  authDomain: FIREBASE_AUTH_DOMAIN,
  projectId: FIREBASE_PROJECT_ID,
  storageBucket: FIREBASE_STORAGE_BUCKET,
  messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
  appId: FIREBASE_APP_ID,
};

const app = initializeApp(firebaseConfig);

const auth = getAuth();
const db = getFirestore(app);
const functions = getFunctions(app);

const getAssets = httpsCallable(functions, "getAssetsCallable");
const getConvertedPrice = httpsCallable(functions, "getConvertedPriceCallable");
const createTransactionPixCallable = httpsCallable(
  functions,
  "createTransactionPixCallable"
);
const createTransactionMetamaskCallable = httpsCallable(
  functions,
  "createTransactionMetamaskCallable"
);
const getTransactionsCallable = httpsCallable(
  functions,
  "getTransactionsCallable"
);
const getUserCallable = httpsCallable(functions, "getUserCallable");
const updateUserCallable = httpsCallable(functions, "updateUserCallable");

// eslint-disable-next-line no-restricted-globals
// if (location.hostname === "localhost") {
//   connectFirestoreEmulator(db, "localhost", 8080);
//   connectAuthEmulator(auth, "http://localhost:9099");
// }

// export default firebase;
export {
  db,
  auth,
  getAssets,
  getConvertedPrice,
  createTransactionPixCallable,
  getTransactionsCallable,
  getUserCallable,
  updateUserCallable,
  createTransactionMetamaskCallable,
};
