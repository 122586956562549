import { TextField } from '@mui/material';
import { t } from 'i18next';
import { inputStyle } from '../style';

export const CpfInput = ({ country, document, change }) => (
  <TextField
    margin="normal"
    fullWidth
    required
    id="document"
    label={country === 'Brazil' ? t('account.document') : 'ID'}
    name="document"
    className="inputForm input-account"
    value={document}
    onChange={change}
    style={inputStyle}
  />
);
