const inputStyle = {
	background: "#2CFFF61D 0% 0% no-repeat padding-box",
	border: "1px solid #1DFDFF !important",
	opacity: 1,
	borderRradius: "5px",
	marginTop: "10px",
};

const buttonStyled = (
	colorBtn,
	fontStyled = "21px/13px",
	paddingStyled = "20px 80px 20px 80px",
	colorBorder = "#3CF584"
) => ({
	font: `normal normal bold ${fontStyled} Anek Latin !important`,
	color: "#FFFFFF !important",
	background: `${colorBtn} !important`,
	borderRadius: "8px",
	borderBottomLeftRadius: "20px !important",
	borderTopRightRadius: "20px !important",
	textAlign: "center",
	letterSpacing: "0px",
	padding: paddingStyled,
	border: `2px solid ${colorBorder}`,
});

export { inputStyle, buttonStyled };
