/* eslint-disable react/jsx-props-no-spreading */
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";

import { tabPanelStyle } from "./styles";

export default function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ ...tabPanelStyle }}>
					<Typography component="span">{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	// eslint-disable-next-line react/require-default-props
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};
