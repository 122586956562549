import BannerSite from "../../../../assets/images/Banner_site.webp";
import BannerMobile from "../../../../assets/images/Banner_site_mobile.webp";
export const pt = {
  account: {
    name: "Nome",
    email: "Email",
    cell: "Celular",
    document: "CPF",
    country: "País",
    label_country: "Selecione seu país",
    connect_wallet_by: "CONECTE WALLET VIA PC/DESKTOP",
    connected: "Conectada",
    save: "Salvar",
    connect_wallet: "Conectar Wallet",
    description: `Preencha os dados obrigatórios (*) para completar seu cadastro e ter acesso à Universus Store`,
    description_confirmed: "Carteira confirmada, tudo pronto",
    alerts: {
      success: {
        message: "Seus dados foram atualizados com sucesso!",
        confirmButton: "Voltar para loja",
        cancelButton: "Fechar"
      }
    }
  },
  auth: {
    register: "Registrar",
    password: "Senha",
    remember: "Relembrar",
    forgot: "Recuperar sua senha",
    login: "Entrar",
    sign_up: "Cadastrar",
    login_with_google: "Entrar com google",
    password_not_match: "Senha não confere",
    referred_by: "Indicado por",
    promo_code: "Código",
    password_confirm: "Confirmar sua senha",
    register_with_google: "Registrar com Google",
    password_recover: "Recuperar senha",
    login_banner: "",
    login_success: `Seu login foi feito com sucesso, aguarde enquanto te levamos para o dashboard.`,
    register_success: `Seu registro foi feito com sucesso, aguarde enquanto te levamos para o dashboard.`,
    register_success_store: `Seu usuário foi criado com sucesso, agora é so preencher as informações para ir as compras!`,
  },
  errors: {
    page_notfound: "Página não encontrada!",
    back_login: "Voltar ao login",
    empty: "Nenhuma transação efetuada.",
    list_transactios:
      "Ocorreu um erro ao listar suas transações, por favor volte para o Dashboard e tente mais tarde.",
  },
  share: {
    or: "Ou",
    tab_android_tickets: "Tickets",
    tab_prepared: "Preparados",
    tab_opened: "Abertos",
    wallet_confirm: "Carteira confirmada, tudo pronto.",
    click_here: "Clique aqui",
    payment: "Pagamento",
    loading_transactions: "Carregando transações",
    know_more: "Saiba mais",
    purchase: "Comprar com PIX ou Crypto",
    brl: 'R$',
    unavailable: "Indisponível",
    open_item: "Visualizar",
    prepare: "Preparar",
    opening: "Abrindo",
    open_android: "Abrir",
    not_informed: "Não Informada",
  },
  menus: {
    account: "Minha Conta",
    tickets: "Meus tickets",
    store: "Extrato compras",
    logout: "Sair",
  },
  feedbacks: {
    extension_metamask_required: `É necessario ter a extensão da metamask instalada para poder utilizar esta função`,
    wallet_register_required: `É necessario ter a mesma Wallet cadastrada conectada na extensão metamask.`,
    wallet_required: "Você precisa informar uma Wallet para receber seus NFTs.",
    verify_email: `Atenção! Verifique seu email e valide a sua Wallet para receber seus NFTs.`,
    not_informed: "Não Informada",
    in_validation: "Em validação",
    finishStore: 'Vendas encerradas, para adquirir sua nave',
    get_androids:
      "Comprando apenas um pack de androids, você já pode jogar Universus!",
    improve_your_game:
      "Naves possibilitam um grande aumento nos planetas disponíveis para explorar e nos recursos a serem recebidos!",
    explanatory_video: "Video explicativo",
    mobile_required:
      "Para usar essa função, é essencial que esteja utilizando o navegador no desktop para que funcione.",
    mobile_link: "Retornar ao Dashboard",
  },
  payments: {
    awaiting: "Aguardando pagamento",
    processing: "Processando pagamento",
    paid: "Pago",
    canceled: "Cancelado",
    creating_nft: "Criando NFT",
    transferred: "NFT criado e transferido para a Wallet",
  },
  metamask: {
    connected: "Você esta conectado à Wallet Metamask.",
    disconnected: "Por favor, faça login em sua carteira Metamask.",
    buttons: {
      tryAgain: "Tentar novamente",
      close: "Fechar",
    },
  },
  tickets: {
    loading: "Carregando NFTs",
    ticketEmpty: "Nenhum ticket disponível",
    preparedEmpty: "Nenhum ticket preparado",
    readyEmpty: "Nada encontrado",
    modal: {
      buy: {
        loading: "Carregando...",
        common: "Comum",
        exotic: "Exótico",
        epic: "Épico",
        unusual: "Incomum",
        rare: "Raro",
        mythical: "Mítico",
        buttonPix: "Comprar com PIX",
        buttonCripto: " Comprar com Cripto",
      },
    },
  },
  dashboard: {
    banner: {
      site: BannerSite,
      mobile: BannerMobile,
    },
    modal: {
      title: "Aviso",
      maintenance: "A Universus Store está sendo atualizada, voltaremos em alguns instantes"
    }
  },
};
