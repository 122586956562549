import BannerSite from "../../../../assets/images/Banner_site_en.webp";
import BannerMobile from "../../../../assets/images/Banner_site_mobile_en.webp";

export const en = {
  account: {
    name: "Name",
    email: "Email",
    cell: "Mobile Number",
    document: "Personal ID",
    country: "Country",
    label_country: "Select your country",
    connect_wallet_by: "CONNECT WALLET USING A PC/DESKTOP",
    connected: "Connected",
    save: "Save",
    connect_wallet: "Connect Wallet",
    description: `Fill in the mandatory data (*) to complete your registration and gain access to the Universus Store`,
    description_confirmed: "Wallet confirmed, all set",
    alerts: {
      success: {
        message: "Your data has been successfully updated!",
        confirmButton: "Back to store",
        cancelButton: "Close"
      }
    }
  },
  auth: {
    register: "Register",
    password: "Password",
    remember: "Remember",
    forgot: "Forgot your password",
    login: "Login",
    sign_up: "Sign up",
    login_with_google: "Login with google",
    password_not_match: "Password don`t match",
    referred_by: "Referred by",
    promo_code: "User Code",
    password_confirm: "Confirm your password",
    register_with_google: "Register with Google",
    password_recover: "Recover password",
    login_banner: "",
    login_success: `Your login was successful, please wait while we take you to the dashboard.`,
    register_success: `Sign up was successful, please wait while we take you to the dashboard.`,
    register_success_store: `Your User has been successfully created, please fill your personal information to begin shopping!`,
  },
  errors: {
    page_notfound: "Page not found",
    back_login: "Return to login",
    empty: "No transactions found.",
    list_transactios:
      "There was an error listing your transactions, please go back to the Dashboard and try again later.",
  },
  share: {
    or: "Or",
    tab_android_tickets: "Tickets",
    tab_prepared: "Prepared",
    tab_opened: "Opened",
    wallet_confirm: "Wallet confirmed, all set",
    click_here: "Click here",
    payment: "Payment",
    loading_transactions: "Loading transactions",
    know_more: "Know more",
    not_informed: "Not informed",
    purchase: "Buy with PIX or Crypto",
    brl: 'BRL',
    unavailable: "Unavailable",
    open_item: "View",
    prepare: "Prepare",
    opening: "Opening",
    open_android: "Open",
  },
  menus: {
    account: "My account",
    tickets: "My tickets",
    store: "My orders",
    logout: "Logout",
  },
  feedbacks: {
    extension_metamask_required: `It is necessary to have the Metamask browser extension installed to be able to use this feature`,
    wallet_register_required: `It is necessary to have the same registered Wallet connected on the Metamask browser extension.`,
    wallet_required: "You need to register a Wallet to receive your NFTs.",
    verify_email: `Heads up! Check your email and validate your Wallet to receive your NFTs`,
    not_informed: "Not informed",
    in_validation: "In validation",
    finishStore: 'Sales closed, to purchase your spaceship',
    get_androids:
      "By purchasing just one pack of Androids, you can now play Universus!",
    improve_your_game:
      "Spaceships allows a huge increase in planets you can reach and also in the resources extracted!",
    explanatory_video: "Explanatory video",
    mobile_required:
      "To use this function, it is essential that you are using the desktop browser for it to work.",
    mobile_link: "Return to Dashboard",
  },
  payments: {
    awaiting: "Awaiting payment",
    processing: "Processing payment",
    paid: "Paid",
    canceled: "Cancelled",
    creating_nft: "Creating NFT",
    transferred: "NFT created and transferred to user Wallet",
  },
  metamask: {
    connected: "You're connected to Metamask",
    disconnected: "Please, login to your Metamask wallet.",
    buttons: {
      tryAgain: "Try again",
      close: "Close",
    },
  },
  tickets: {
    loading: "Loading NFTs",
    ticketEmpty: "No ticket available",
    preparedEmpty: "No ticket prepared",
    readyEmpty: "No android found",
    modal: {
      buy: {
        loading: "Loading...",
        common: "Common",
        exotic: "Exotic",
        epic: "Epic",
        unusual: "Uncommon",
        rare: "Rare",
        mythical: "Mythical",
        buttonPix: "Buy with PIX",
        buttonCripto: "Buy with Crypto",
      },
    },
  },
  dashboard: {
    banner: {
      site: BannerSite,
      mobile: BannerMobile,
    },
    modal: {
      title: "Warning",
      maintenance: "The Universus Store is being  updated, we will be back soon"
    }
  },
};
