import { IS_DEV } from "../../../../infra/config";
import { useCallback, useEffect, useState } from "react";
import androidImg from "../../../../assets/images/android-animation.gif";
import { showError } from "../../../../infra/helpers/alerts";
import { params } from "../data/network-params";
import { TicketModel } from "../models/ticket.model";
import { getReadProvider, getRevealContract } from "../services";
import { useTicketsStore } from "../store/tickets";
import Sentry from "./../../../../infra/sentry/index";

export function useTicketsPrepareds(wallet: string) {
  const [isLoading, setIsLoading] = useState(false);
  const { items, updateItems, updatedTime } = useTicketsStore();

  const onLoad = useCallback(async () => {
    const provider = getReadProvider();

    const revealContract = getRevealContract(provider, params.reveal);

    const actives = await revealContract.getActiveRequestsByAccount(wallet);

    const result: TicketModel[] = [];

    try {
      for (const item of actives) {
        const requestId = item._hex;

        const res = await revealContract.requests(requestId);

        const tokenId = res.boxId.toNumber();

        result.push(
          new TicketModel({
            id: tokenId,
            tokenId: `${tokenId}`,
            requestId,
            contract: revealContract,
            isSpaceship: false,
            imageUrl: androidImg,
            status: "prepared",
            name: `Android #${tokenId}`,
          })
        );
      }
    } catch (err: any) {

      Sentry.captureMessage(JSON.stringify({
        wallet,
        action: "loadTicketsPrepareds",
      }));

      Sentry.setContext("data", {
        wallet,
        action: "loadTicketsPrepareds",
      });
      Sentry.captureException(err);

      if (IS_DEV) {
        showError(err.message);
      } else {
        showError();
      }
    }

    return result;
  }, [wallet]);

  useEffect(() => {
    setIsLoading(true);

    (async () => {
      const items = await onLoad();

      updateItems(items);

      setIsLoading(false);
    })();

    return () => {
      updateItems([]);
    };
  }, [onLoad, updateItems, updatedTime]);

  return {
    isLoading,
    items,
  };
}
