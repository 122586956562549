// export const networkParams = {
//   137: {
//     reveal: "0x97c07a719b4c375B2c0F37272A8E3C591763a80B",
//     lootBoxSale: "0xa0F80B88A5697979b2dE393095Df5231731dfA99",
//     lootBoxPromo: "0x82D3E054Ff2ec6a085D6e6F63a350730a01E9d2D",
//     android: "0xA19Ce738e3c6BA3c0815aeaF658B3640985cA0d2",
//   },
//   80001: {
//     reveal: "0x0eDbce447a481A9ef548Fc71032DdBFE14AC7c4F",
//     lootBoxSale: "0x31F741AFff9dc0c219595BEA473A004E3690C224",
//     lootBoxPromo: "0x426920acd96cB326d307AbD20c482e981510Cc87",
//     android: "0x72d522c8aB7e70232a039Dc81b9b627725F33edF",
//   },
// };

import { REVEAL, LOOTBOX_SALE, LOOTBOX_PROMO, ANDROID, SPACESHIP } from '../../../../infra/config';

export const params = {
  reveal: REVEAL,
  lootBoxSale: LOOTBOX_SALE,
  lootBoxPromo: LOOTBOX_PROMO,
  android: ANDROID,
  spaceship: SPACESHIP
};

//console.log("params", params);
